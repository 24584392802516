import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { ColumnDef } from '@tanstack/react-table';
import { TripProps } from '../../user.interface';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useDetailsHook from './useDetailsHook';
import { useEffect, useRef } from 'react';
import 'react-h5-audio-player/lib/styles.css';

type ReactH5AudioPlayerRef = AudioPlayer | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DealDetailsNotes = ({ audio }: any) => {
  const {
    audioDetails,
    IsLoadingaudioDetails,
    audioLink,
    setAudioLink,
    audioPagination,
    setAudioPagination
  } = useDetailsHook();
  const playerRef = useRef<ReactH5AudioPlayerRef>(null);

  const columns: ColumnDef<TripProps>[] = [
    {
      accessorKey: 'file',
      header: 'Title',
      cell: ({ row: { original } }) => {
        const { file, created_at } = original;
        return (
          <span
            onClick={() => setAudioLink(file)}
            className="fw-semibold cursor-pointer"
          >
            Audio Recording {dayjs(created_at).format('DD-MM-YYYY HH:mm')}
          </span>
        );
      },
      enableSorting: false,
      meta: {
        headerProps: { style: { minWidth: 250 }, className: 'py-2' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: audioDetails?.results ?? [],
    columns,
    pageSize: 6,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination: setAudioPagination,
    pageCount: audioDetails?.pages,
    manualPagination: true,
    state: {
      pagination: audioPagination
    }
  });

  useEffect(() => {
    if (audio) {
      playerRef?.current && playerRef?.current?.audio?.current?.play();
    } else {
      playerRef?.current && playerRef?.current?.audio?.current?.pause();
    }
  }, [audio]);

  if (IsLoadingaudioDetails) {
    return <>Loading...</>;
  }

  return (
    <Row className="g-3">
      <Col xl={6} xxl={7} className="d-flex flex-column gap-3">
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            isLoading={IsLoadingaudioDetails}
          />
          <AdvanceTableFooter
            navBtn
            showViewAllBtn={false}
            count={audioDetails?.count}
          />
        </AdvanceTableProvider>
      </Col>
      {audioLink && (
        <Col xl={6} xxl={5}>
          <div className="w-full d-flex-col align-items-center justify-content-center player-wrapper">
            <div className="w-full d-flex align-items-center justify-content-center mb-4">
              <FontAwesomeIcon
                icon={faMicrophone}
                className="tab-icon-color"
                style={{
                  width: '200px',
                  height: '200px'
                }}
              />
            </div>
            <AudioPlayer
              ref={playerRef}
              autoPlay={false}
              src={audioLink}
              showSkipControls={false}
              showJumpControls={false}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default DealDetailsNotes;
