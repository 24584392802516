/* eslint-disable @typescript-eslint/no-explicit-any */
import { get_api } from 'apis';
import { ApiRoutes } from 'common/api-routes';
import { PageIndex, VideoAudioPageLimit } from 'common/variables';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { SearchObject } from './search.interface';
import { toast } from 'react-toastify';
import { Routes } from 'common/router-name';
import { useAppSelector } from '../../../redux/hooks';
import { AuthUserState } from '../../../redux/features/authSlice';

const useSearchHook = () => {
  const auth = useAppSelector(state => state.auth.user) as AuthUserState;
  const { common, search } = ApiRoutes;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q') ?? '';
  const [searchPagination, setSearchPagination] = useState({
    pageIndex: PageIndex,
    pageSize: 20
  });
  const { EventDetailsPage, UserEventsProfile, MembersRoute, PendingUserQue } =
    Routes;

  const fetchGlobalSearchResult = async (
    value1: string | undefined,
    value2: number,
    value3: number
  ): Promise<SearchObject | null> => {
    const url = `${common}${search}`;
    try {
      value2 = value2 + 1;
      const data = (await get_api(
        `${url}?q=${encodeURIComponent(
          value1 ?? ''
        )}&page=${value2}&page_size=${value3}`
      )) as SearchObject;
      data.pages = Math.ceil(data.count / VideoAudioPageLimit);
      return data;
    } catch (error: any) {
      toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: searchResult,
    isLoading: IsLoadingsearchDetails,
    isError: IsErrorsearchDetails,
    error: ErrorsearchDetails
  } = useQuery(
    ['search', query, searchPagination],
    async () =>
      await fetchGlobalSearchResult(
        query,
        searchPagination.pageIndex,
        searchPagination.pageSize
      ),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return {
    searchResult,
    IsLoadingsearchDetails,
    IsErrorsearchDetails,
    ErrorsearchDetails,
    searchPagination,
    setSearchPagination,
    EventDetailsPage,
    UserEventsProfile,
    MembersRoute,
    PendingUserQue,
    auth
  };
};

export default useSearchHook;
