import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { totalUsageProps } from '../ecommerce/dashboard.interface';
echarts.use([TooltipComponent, PieChart]);

interface EcomTopCouponsChartProps {
  data: totalUsageProps | undefined;
}

const EcomTopCouponsChart = ({ data }: EcomTopCouponsChartProps) => {
  const { getThemeColor } = useAppContext();

  const getDefaultOptions = (getThemeColor: (name: string) => string) => ({
    color: [
      getThemeColor('primary'),
      getThemeColor('primary-lighter'),
      getThemeColor('info-dark')
    ],

    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: (params: CallbackDataParams) =>
        `<strong>${params.name}:</strong> ${params.percent}%`
    },
    legend: { show: false },
    series: [
      {
        // name: '72%',
        name: `${data?.open_and_used}%`,
        type: 'pie',
        radius: ['100%', '87%'],
        avoidLabelOverlap: false,
        emphasis: {
          scale: false,
          itemStyle: {
            color: 'inherit'
          }
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getThemeColor('body-bg')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 23,
          color: getThemeColor('light-text-emphasis')
        },
        data: [
          { value: data?.open_and_used, name: 'Open and used' },
          { value: data?.open_and_not_used, name: 'Opened and didn’t use' },
          { value: data?.not_opened, name: 'Have not used' }
        ]
      }
    ],
    grid: { containLabel: true }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor)}
      style={{ height: '115px', width: '100%' }}
    />
  );
};

export default EcomTopCouponsChart;
