/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/40x40/avatar.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
// import NotificationDropdownMenu from './NotificationDropdownMenu';
import ThemeToggler from 'components/common/ThemeToggler';
import { useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Messages } from 'common/router-name';
import { ApiRoutes } from 'common/api-routes';
import { authUpdateFlag } from '../../../redux/features/authSlice';
import { patch_api } from 'apis';

const NavItems = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const dispatch = useAppDispatch();
  const authUser = useAppSelector(state => state.auth.user);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [toggleDisable, setToggleDisable] = useState(false);
  const { common, getAdminMemberProfile } = ApiRoutes;

  const handleCheckboxChange = async (acceptCallsFlag: boolean) => {
    setToggleDisable(true);
    const url = `${common}${getAdminMemberProfile}`;
    const data = {
      accept_calls: acceptCallsFlag
    };
    try {
      await patch_api(url, data, dispatch);
      dispatch(authUpdateFlag(data)).then(() => {
        setToggleDisable(false);
      });
      toast.success(
        acceptCallsFlag
          ? Messages.ENABLE_CALL_FLAG_SUCCESS
          : Messages.DISABLE_CALL_FLAG_SUCCESS
      );
      setToggleDisable(false);
    } catch {
      toast.error(Messages.ACCEPT_CALL_FLAG_ERROR);
      setToggleDisable(false);
    }
  };

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {authUser.user_type === 'DS' && (
        <Nav.Item>
          <Form.Check type="checkbox" id="accept_calls" className="form-switch">
            <Form.Check.Input
              type="checkbox"
              name="accept_calls"
              className="cursor-pointer"
              style={{ width: '50px', height: '25px' }}
              checked={authUser.accept_calls ?? false}
              onChange={e => handleCheckboxChange(e.target.checked)}
              disabled={toggleDisable}
            />
          </Form.Check>
        </Nav.Item>
      )}

      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}
      >
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={authUser.profile_image ?? avatar57} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NavItems;
