import { Col, Row } from 'react-bootstrap';
import EcomTotalOrdersCard from '../components/EcomTotalOrdersCard';
import EcomNewCustomersCard from '../components/EcomNewCustomersCard';
import EcomTopCouponsCard from '../components/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from '../components/EcomPayingVsNonPayingCard';
import EcomStats from '../components/EcomStats';
import EcomTotalSells from '../components/EcomTotalSells';
import EventsTable from './events';
import useDashboardHook from './useDashboardHook';

const Ecommerce = () => {
  const {
    getMonthList,
    callTime,
    isLoadingcallTime,
    usage,
    isLoadingusage,
    activeUser,
    isLoadingactiveUser,
    callProportion,
    isLoadingcallProportion
  } = useDashboardHook();
  const monthList = getMonthList();
  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} lg={12} xxl={6}>
            <div className="mb-8">
              <h2 className="mb-2">User Summary Data</h2>
              <h5 className="text-body-tertiary fw-semibold">
                Here’s what’s going on at Crimesly
              </h5>
            </div>
            <EcomStats />
            <hr className="bg-body-secondary mb-6 mt-4" />
            <EcomTotalSells monthList={monthList} />
          </Col>
          <Col xs={12} lg={12} xxl={6}>
            <Row className="g-3">
              <Col xs={12} md={6}>
                <EcomTotalOrdersCard
                  data={callTime}
                  isLoading={isLoadingcallTime}
                />
              </Col>
              <Col xs={12} md={6}>
                <EcomNewCustomersCard
                  data={activeUser}
                  isLoading={isLoadingactiveUser}
                />
              </Col>
              <Col xs={12} md={6}>
                <EcomTopCouponsCard data={usage} isLoading={isLoadingusage} />
              </Col>
              <Col xs={12} md={6}>
                <EcomPayingVsNonPayingCard
                  data={callProportion}
                  isLoading={isLoadingcallProportion}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EventsTable />
      </div>
    </>
  );
};

export default Ecommerce;
