/* eslint-disable @typescript-eslint/no-explicit-any */
import AdvanceTable from 'components/base/AdvanceTable';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { PendingUserQueResult } from './pendintUqeInterface';
import usePendingUserQue from './usePendingUserQue';
import { PageSize } from 'common/variables';
import { Routes } from 'common/router-name';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

const EcomLatestReviewsTable = () => {
  const { EventDetailsPage } = Routes;
  const {
    userQueList,
    pagination,
    setPagination,
    formatTimestamp,
    formatTimestamp2,
    searchValue,
    handleSearchInputChange
  } = usePendingUserQue();

  const columns: ColumnDef<PendingUserQueResult>[] = [
    {
      accessorKey: 'user',
      header: () => 'User',
      cell: ({ row: { original } }) => {
        const {
          event: {
            user: { first_name, last_name }
          }
        } = original;
        return (
          <Link to="#" className="d-flex align-items-center text-body">
            <Avatar size="l" variant="name">
              {first_name?.charAt(0).toUpperCase() ?? ''}
            </Avatar>
            <h6 className="mb-0 ms-3 text-body">{`${first_name ?? ''} ${
              last_name ?? ''
            }`}</h6>
          </Link>
        );
      },
      enableSorting: true,
      meta: {
        headerProps: { style: { minWidth: 160 }, className: 'py-2' }
      }
    },
    {
      accessorKey: 'trip',
      header: 'Trip',
      cell: ({ row: { original } }) => {
        const {
          event: { event_id, navigation_data }
        } = original;
        return (
          <Link
            to={`${EventDetailsPage}/${event_id}`}
            className="d-flex align-items-center"
          >
            Started at {navigation_data?.src_place_name ?? 'unknown'} - - Routed
            to {navigation_data?.dst_place_name ?? 'unknown destination'}
          </Link>
        );
      },
      meta: {
        headerProps: { style: { minWidth: 200 } }
      }
    },
    {
      accessorKey: 'waitTime',
      header: 'Wait Time',
      // cell: () => {
      //   return '00:00';
      // },
      cell: ({ row: { original } }) => {
        const { call_started_at } = original;
        return formatTimestamp2(call_started_at);
      },
      meta: {
        headerProps: { style: { minWidth: 110 } }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { session_status } = original;
        const color =
          session_status === 'STARTED'.toUpperCase() ? 'warning' : 'success';
        const status =
          session_status === 'STARTED'.toUpperCase()
            ? 'IN QUEUE WAITING'
            : 'CURRENT CALL';
        return (
          <Badge
            bg={color}
            variant="phoenix"
            iconPosition="end"
            className="fs-10"
          >
            {status}
          </Badge>
        );
      },
      meta: {
        headerProps: { className: 'ps-5' },
        cellProps: { className: 'ps-5' }
      }
    },
    {
      accessorKey: 'totalCallTime',
      header: 'Total call Time',
      cell: ({ row: { original } }) => {
        const { call_started_at } = original;
        return formatTimestamp2(call_started_at);
      },
      meta: {
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end white-space-nowrap' }
      }
    },
    {
      accessorKey: 'callOption',
      enableSorting: false,
      header: 'Call Option',
      cell: ({ row: { original } }) => {
        const { session_status } = original;
        const btnText =
          session_status === 'STARTED'.toUpperCase() ? 'answer' : 'hold';
        const callOption = 'answer';
        return (
          <div className="position-relative">
            <Button
              variant={
                callOption === 'hold'.toUpperCase()
                  ? 'outline-primary'
                  : 'primary'
              }
              className="me-1 fs-10"
              size="sm"
              // onClick={() => getVideoSDKJWT(session_name)}
            >
              {btnText.toUpperCase()}
            </Button>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-end' }
      }
    },
    {
      accessorKey: 'startCallTime',
      header: 'Start Call Time',
      cell: ({ row: { original } }) => {
        const { call_started_at } = original;
        return formatTimestamp(call_started_at);
      },
      meta: {
        headerProps: { className: 'text-end' },
        cellProps: { className: 'text-end white-space-nowrap' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: userQueList?.results ?? [],
    columns,
    pageSize: PageSize,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination,
    pageCount: userQueList?.pages,
    manualPagination: true,
    state: {
      pagination
    }
  });

  return (
    <>
      <Row className="g-3 mb-4">
        <Col xs="auto" sm={3}>
          <SearchBox
            placeholder="Search user"
            onChange={handleSearchInputChange}
            value={searchValue}
            className="w-100"
          />
        </Col>
      </Row>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter
          navBtn
          showViewAllBtn={false}
          count={userQueList?.count}
          className="mb-3"
        />
      </AdvanceTableProvider>
    </>
  );
};

export default EcomLatestReviewsTable;
