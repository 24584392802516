/* eslint-disable @typescript-eslint/no-explicit-any */
import { faKey, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SettingsChangePassword = ({ handleBlur, handleChange }: any) => {
  return (
    <>
      <div className="form-icon-container mb-3">
        <Form.Floating>
          <Form.Control
            id="oldPassword"
            name="oldPassword"
            type="password"
            placeholder="Old Password"
            className="form-icon-input"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label
            htmlFor="oldPassword"
            className="form-icon-label text-body-tertiary"
          >
            OLD PASSWORD
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faLock} className="text-body fs-9 form-icon" />
        <ErrorMessage
          name="oldPassword"
          component="div"
          className="fs-9 fw-normal text-danger"
        />
      </div>
      <div className="form-icon-container mb-3">
        <Form.Floating>
          <Form.Control
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="New password"
            className="form-icon-input"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label
            htmlFor="newPassword"
            className="form-icon-label text-body-tertiary"
          >
            NEW PASSWORD
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        <ErrorMessage
          name="newPassword"
          component="div"
          className="fs-9 fw-normal text-danger"
        />
      </div>
      <div className="form-icon-container">
        <Form.Floating>
          <Form.Control
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirm New password"
            className="form-icon-input"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label
            htmlFor="confirmPassword"
            className="form-icon-label text-body-tertiary"
          >
            CONFIRM NEW PASSWORD
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        <ErrorMessage
          name="confirmPassword"
          component="div"
          className="fs-9 fw-normal text-danger"
        />
      </div>
    </>
  );
};

export default SettingsChangePassword;
