/* eslint-disable @typescript-eslint/no-explicit-any */
// import SettingsPanel from 'components/settings-panel/SettingsPanel';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
// import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { lockScreen } from './redux/features/authSlice';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import useAppHook from 'useAppHook';
import { toast } from 'react-toastify';
import { Routes } from 'common/router-name';

const App = () => {
  const lockTime = parseInt(process.env.REACT_APP_LOCK_TIME ?? '900000');
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { statusFlag, setStatusFlag } = useAppHook();
  const authUser = useAppSelector(state => state.auth.user);
  const navigate = useNavigate();

  // const {
  //   settingsPanelConfig: { showSettingPanelButton },
  //   setSettingsPanelConfig
  // } = useSettingsPanelContext();

  // config: { theme, isRTL }
  const {
    config: { theme }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    if (authUser && authUser?.needs_password_reset) {
      console.log('calling....');
      navigate(`${Routes.Auth}${Routes.ChangePassword}`);
    }
  }, [authUser, authUser?.needs_password_reset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(() => {
  //   setSettingsPanelConfig({
  //     openSettingPanel: false
  //   });
  // }, [isRTL]);

  useEffect(() => {
    let timerId: any;

    const resetTimer = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        // Call your API here to change the status flag
        setStatusFlag(true);
        dispatch(lockScreen());
      }, lockTime); // 15 minutes in milliseconds
    };

    // Start the timer when the component mounts or whenever statusFlag changes
    resetTimer();

    // Reset the timer whenever there's an interaction
    const handleInteraction = () => {
      resetTimer();
    };

    // Add event listeners to track interactions
    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(timerId);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);
    };
  }, [statusFlag]);

  useEffect(() => {
    if (authUser?.needs_password_reset) {
      toast.warning('Your password needs to be reset.', {
        autoClose: false,
        closeOnClick: true,
        className: 'text-dark'
      });
    }
  }, []);

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {/* {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )} */}
        </>
      )}
    </>
  );
};

export default App;
