/* eslint-disable @typescript-eslint/no-explicit-any */
import CoverUpload from 'components/common/CoverUpload';
import coverImage from 'assets/img/generic/59.png';
import AvatarUpload from 'components/common/AvatarUpload';
import { Card, Col, Row } from 'react-bootstrap';
import { CSSProperties } from 'react';
import { MemberRoot } from '../profile/profile.interface';
import useProfileHook from '../profile/useProfileHook';

interface SocialProfileCardProps {
  showAbout?: boolean;
  avatar: string;
  className?: string;
  profileData?: MemberRoot;
}

const SocialProfileCard = ({
  avatar,
  className,
  profileData
}: SocialProfileCardProps) => {
  const { uploadImage, uploadCoverImage } = useProfileHook();
  return (
    <Card className={className}>
      <Card.Header
        className="position-relative mb-6"
        style={{ minHeight: '130px' }}
      >
        <CoverUpload
          src={profileData?.cover_image ?? coverImage}
          gradient={
            'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%)' as CSSProperties
          }
          onChange={uploadCoverImage}
        />
        <AvatarUpload
          size="4xl"
          src={avatar}
          thumbnail
          className="feed-avatar-profile"
          imageClassName="border-0"
          onChange={uploadImage}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-wrap mb-2 align-items-center">
              <h3 className="me-2">
                {profileData?.first_name} {profileData?.last_name}
              </h3>
              <span className="fw-normal fs-8">
                u/
                {`${profileData?.first_name
                  .toLowerCase()
                  .replace(/\s+/g, '')}${profileData?.last_name
                  .toLowerCase()
                  .replace(/\s+/g, '')}`}
              </span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SocialProfileCard;
