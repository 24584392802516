import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getDates } from 'helpers/utils';
import dayjs from 'dayjs';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { getSixDaysBeforeDate } from 'helpers/date-flter';
echarts.use([TooltipComponent, BarChart]);

interface EcomTotalOrdersChartProps {
  data: number[] | undefined;
}

const EcomTotalOrdersChart = ({ data = [] }: EcomTotalOrdersChartProps) => {
  const { getThemeColor } = useAppContext();
  const todaysDate = new Date();
  const currentDate = `${todaysDate.getFullYear()}-${String(
    todaysDate.getMonth() + 1
  ).padStart(2, '0')}-${String(todaysDate.getDate()).padStart(2, '0')}`;
  const newcurrentDate = dayjs(currentDate).format('MM/DD/YYYY');
  const sixDayBeforeDate = getSixDaysBeforeDate(currentDate);
  const newsixDayBeforeDate = dayjs(sixDayBeforeDate).format('MM/DD/YYYY');
  const getDefaultOptions = (getThemeColor: (name: string) => string) => ({
    color: getThemeColor('primary'),
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: (params: CallbackDataParams) =>
        `<strong>${dayjs(params.name).format('DD MMM')}:</strong> ${
          params.value
        }`
    },
    xAxis: {
      type: 'category',
      data: getDates(
        new Date(newsixDayBeforeDate),
        new Date(newcurrentDate),
        1000 * 60 * 60 * 24
      ),
      show: true,
      boundaryGap: false,
      axisLine: {
        show: true,
        lineStyle: { color: getThemeColor('secondary-bg') }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        formatter: (value: string) => dayjs(value).format('DD MMM'),
        interval: 7,
        showMinLabel: true,
        showMaxLabel: true,
        color: getThemeColor('secondary-color')
      }
    },
    yAxis: {
      show: false,
      type: 'value',
      boundaryGap: false
    },
    series: [
      {
        type: 'bar',
        barWidth: '5px',
        // data: [120, 200, 400, 80, 70, 110, 120],
        data: data ?? [],
        showBackground: true,
        symbol: 'none',
        itemStyle: {
          borderRadius: 10
        },
        backgroundStyle: {
          borderRadius: 10,
          color: getThemeColor('primary-bg-subtle')
        }
      }
    ],
    grid: { right: 10, left: 10, bottom: 0, top: 0 }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor)}
      style={{ height: '85px', width: '115px' }}
    />
  );
};

export default EcomTotalOrdersChart;
