/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseISO, format } from 'date-fns';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { EventListProps } from '../ecommerce/dashboard.interface';
import { get_api } from 'apis';
import { Limit, PageIndex, PageSize } from 'common/variables';
import { ApiRoutes } from 'common/api-routes';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';

function useQueryFunction() {
  return new URLSearchParams(useLocation().search);
}

const useFilteredUserEventHook = () => {
  const query = useQueryFunction();
  const qValue = query.get('q');
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: PageSize
  });
  const [filter, setfilter] = useState({
    name: 'All Users',
    isActive: true
  });
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const dispatch = useAppDispatch();

  const { common, events } = ApiRoutes;

  const fetchEvents = async (
    value1: number,
    value2: number,
    value3: string | null
  ): Promise<EventListProps> => {
    const url = `${common}${events}`;
    value1 = value1 + 1;
    const search = value3 ? `&search=${value3}` : '';
    const data = (await get_api(
      `${url}?page=${value1}&page_size=${value2}${search}`,
      dispatch
    )) as EventListProps;
    data.pages = Math.ceil(data.count / Limit);
    return data;
  };

  const {
    data: eventList,
    isLoading: isLoadingeventList,
    isError: isErroreventList,
    error: erroreventList
  } = useQuery(
    ['event-list', pagination, qValue],
    async () =>
      await fetchEvents(pagination.pageIndex, pagination.pageSize, qValue),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const formatTimestamp = (timestamp: string): string => {
    const date = parseISO(timestamp);
    const now = new Date();

    const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (differenceInMinutes < 1) {
      return 'just now';
    } else if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    } else {
      return format(date, 'MMM dd, h:mm a');
    }
  };

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    sortType,
    setSortType,
    filter,
    setfilter,
    formatTimestamp,
    eventList,
    isLoadingeventList,
    isErroreventList,
    erroreventList,
    pagination,
    setPagination,
    qValue
  };
};

export default useFilteredUserEventHook;
