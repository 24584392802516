import { parseISO } from 'date-fns';

// Function to get the date 6 days before the current date
export function getSixDaysBeforeDate(
  currentDate = '',
  formatType = '',
  previous6Date = false
) {
  // Create a new Date object using the current date
  const date = currentDate ? new Date(currentDate) : new Date();

  // Subtract 6 days from the date
  if (!previous6Date) date.setDate(date.getDate() - 6);

  // Format the date to a readable string (optional)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  if (formatType) {
    return `${month}/${day}/${year}`;
  }
  return `${year}-${month}-${day}`;
}

export const formatTimestamp = (timestamp: string): string => {
  const date = parseISO(timestamp);
  const now = new Date();

  const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);
  const differenceInHours = differenceInMinutes / 60;
  const differenceInDays = differenceInHours / 24;

  if (differenceInMinutes < 1) {
    return 'just now';
  } else if (differenceInMinutes < 60) {
    return `${Math.floor(differenceInMinutes)} minutes ago`;
  } else if (differenceInHours < 24) {
    return `${Math.floor(differenceInMinutes / 60)} hours ago`;
  }
  // else if (differenceInDays < 7) {
  //   return `${Math.floor(differenceInDays)} days ago`;
  // }
  else {
    return `${Math.floor(differenceInDays)} days ago`;
    // return format(date, 'MMM dd, h:mm a');
  }
};
