import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthUserState } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';
import { Routes } from 'common/router-name';
// import CallNotification from 'pages/dashboard/socket';

const MainLayout = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const authUser = useAppSelector(state => state.auth.user) as AuthUserState;
  const { contentClass, footerClass } = useMainLayoutContext();

  return (
    <>
      <Container fluid className="px-0">
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        {navbarPosition === 'vertical' && <NavbarTopDefault />}
        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
          <NavbarTopHorizontal />
        )}
        {navbarPosition === 'dual' && <NavbarDual />}
        <div className={classNames(contentClass, 'content')}>
          {authUser.user_type === 'DS' &&
            authUser.accept_calls &&
            pathname !== Routes.PendingUserQue && (
              <p
                className="p-3 fw-semibold cursor-pointer"
                style={{
                  color: '#ffffff',
                  backgroundColor: '#F48B8B',
                  marginTop: '-32px',
                  marginLeft: '-40px',
                  marginRight: '-40px',
                  textAlign: 'center',
                  transition: '1s'
                }}
                onClick={() => navigate(Routes.PendingUserQue)}
              >
                INCOMING CALL - YOU WILL BE REDIRECTED TO THE USER
              </p>
            )}
          {/* <CallNotification /> */}
          <Outlet />
          <Footer className={classNames(footerClass, 'position-absolute')} />
        </div>
      </Container>
    </>
  );
};

export default MainLayout;
