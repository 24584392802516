import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import dayjs from 'dayjs';
import { ColumnDef } from '@tanstack/react-table';
import { EventCallProps } from '../../user.interface';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useDetailsHook from './useDetailsHook';
import { useEffect, useRef } from 'react';
import DsCall from 'assets/img/icons/ds_call.svg';
import 'react-h5-audio-player/lib/styles.css';

type ReactH5AudioPlayerRef = AudioPlayer | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DealDetailsCall = ({ call }: any) => {
  const {
    callDetails,
    IsLoadingcallDetails,
    callLink,
    setCallLink,
    callPagination,
    setCallPagination
  } = useDetailsHook();
  const playerRef = useRef<ReactH5AudioPlayerRef>(null);

  const columns: ColumnDef<EventCallProps>[] = [
    {
      accessorKey: 'file',
      header: 'Title',
      cell: ({ row: { original } }) => {
        const { recording_link, created_at } = original;
        return (
          <span
            onClick={() => setCallLink(recording_link)}
            className="fw-semibold cursor-pointer"
          >
            Dispatcher Call Recording{' '}
            {dayjs(created_at).format('DD-MM-YYYY HH:mm')}
          </span>
        );
      },
      enableSorting: false,
      meta: {
        headerProps: { style: { minWidth: 250 }, className: 'py-2' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: callDetails ?? [],
    columns,
    pageSize: 6,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination: setCallPagination,
    pageCount: 0,
    manualPagination: true,
    state: {
      pagination: callPagination
    }
  });

  useEffect(() => {
    if (call) {
      playerRef?.current && playerRef?.current?.audio?.current?.play();
    } else {
      playerRef?.current && playerRef?.current?.audio?.current?.pause();
    }
  }, [call]);

  if (IsLoadingcallDetails) {
    return <>Loading...</>;
  }

  return (
    <Row className="g-3">
      <Col xl={6} xxl={7} className="d-flex flex-column gap-3">
        <AdvanceTableProvider {...table}>
          <AdvanceTable
            tableProps={{
              className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
            }}
            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            isLoading={IsLoadingcallDetails}
          />
          {/* <AdvanceTableFooter navBtn showViewAllBtn={false} count={0} /> */}
        </AdvanceTableProvider>
      </Col>
      {callLink && (
        <Col xl={6} xxl={5}>
          <div className="w-full d-flex-col align-items-center justify-content-center player-wrapper">
            <div className="w-full d-flex align-items-center justify-content-center mb-4">
              <img src={DsCall} width={200} height={200} />
            </div>
            <AudioPlayer
              ref={playerRef}
              autoPlay={false}
              src={callLink}
              showSkipControls={false}
              showJumpControls={false}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default DealDetailsCall;
