/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseISO, format } from 'date-fns';
import { useState, ChangeEvent } from 'react';
import { useQuery } from 'react-query';
import { get_api } from 'apis';
import { Limit, PageIndex, PageSize } from 'common/variables';
import { ApiRoutes } from 'common/api-routes';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import { PendingUserQueObjectProps } from './pendintUqeInterface';

function useQueryFunction() {
  return new URLSearchParams(useLocation().search);
}

const usePendingUserQue = () => {
  const query = useQueryFunction();
  const qValue = query.get('q');
  const [searchValue, setSearchValue] = useState<string>(qValue ?? '');
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: PageSize
  });
  const [filter, setfilter] = useState({
    name: 'All Users',
    isActive: true
  });
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const dispatch = useAppDispatch();

  const { common, dispatcherCallList } = ApiRoutes;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const fetchPendingUserQue = async (
    search: string,
    value1: number,
    value2: number
  ): Promise<PendingUserQueObjectProps> => {
    const url = `${common}${dispatcherCallList}`;
    value1 = value1 + 1;
    const data = (await get_api(
      `${url}?search=${search}&page=${value1}&page_size=${value2}`,
      dispatch
    )) as PendingUserQueObjectProps;
    data.pages = Math.ceil(data.count / Limit);
    return data;
  };

  const {
    data: userQueList,
    isLoading: isLoadinguserQue,
    isError: isErroruserQue,
    error: erroruserQue
  } = useQuery(
    ['user-que-list', searchValue, pagination],
    async () =>
      await fetchPendingUserQue(
        searchValue,
        pagination.pageIndex,
        pagination.pageSize
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const formatTimestamp = (timestamp: string): string => {
    const date = parseISO(timestamp);
    const now = new Date();

    const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (differenceInMinutes < 1) {
      return 'just now';
    } else if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    } else {
      return format(date, 'MMM dd HH:mm:ss');
    }
  };

  const formatTimestamp2 = (timestamp: string): string => {
    const date = parseISO(timestamp);
    // const now = new Date();

    // const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    // if (differenceInMinutes < 1) {
    //   return 'just now';
    // } else if (differenceInMinutes < 60) {
    //   return `${Math.floor(differenceInMinutes)} minutes ago`;
    // } else if (differenceInMinutes < 1440) {
    //   return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    // } else {
    // }
    return format(date, 'HH:mm:ss');
  };

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    sortType,
    setSortType,
    filter,
    setfilter,
    formatTimestamp,
    pagination,
    setPagination,
    qValue,
    userQueList,
    isLoadinguserQue,
    isErroruserQue,
    erroruserQue,
    formatTimestamp2,
    searchValue,
    handleSearchInputChange
  };
};

export default usePendingUserQue;
