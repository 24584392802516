import classNames from 'classnames';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface PageBreadcrumbItem {
  label: string;
  url?: string;
  active?: boolean;
}

interface PageBreadcrumbProps {
  items: PageBreadcrumbItem[];
  className?: string;
}

const PageBreadcrumb = ({ items, className }: PageBreadcrumbProps) => {
  return (
    <Breadcrumb className={classNames(className, 'mb-2')}>
      {items.map(item => (
        <Link
          key={item.label}
          to={item.active ? '#' : item.url || '#'}
          className={`breadcrumb-item ${item.active ? 'active' : ''}`}
        >
          {item.label}
        </Link>
      ))}
    </Breadcrumb>
  );
};

export default PageBreadcrumb;
