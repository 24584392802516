import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Tab } from 'react-bootstrap';
import DealDetailsActivity from './DealDetailsActivity';
import DealDetailsNotes from './DealDetailsNotes';
import DealDetailsMeeting from './DealDetailsMeeting';
// import DealDetailsTask from './DealDetailsTask';
import DealDetailsCall from './DealDetailsCall';
// import DealDetailsAttachments from './DealDetailsAttachments';
// import { attachments } from 'data/project-management/todoListData';
import {
  faChartLine,
  faClipboard,
  faPhone,
  faVideo,
  faImage,
  faMapPin
} from '@fortawesome/free-solid-svg-icons';
import DealDetailsAttachments from './DealDetailsAttachments';
// import useDetailsHook from './useDetailsHook';
import { useEffect, useState } from 'react';
import useDetailsHook from './useDetailsHook';
import RouteTracking from './RouteTracking';

interface TabItem {
  name: string;
  label: string;
  icon: IconProp;
  content?: JSX.Element;
}

type TabName = 'activity' | 'call' | 'video' | 'audio' | 'route' | 'image';

const DealDetailsTab = () => {
  const { tabState, setTabState } = useDetailsHook();
  const [activeTab, setActiveTab] = useState<TabName>('activity');

  const tabitems: TabItem[] = [
    {
      name: 'Activity',
      label: 'Event Details',
      icon: faChartLine,
      content: <DealDetailsActivity />
    },
    {
      name: 'Call',
      label: 'Call Details',
      icon: faPhone,
      content: <DealDetailsCall call={tabState.call} />
    },
    {
      name: 'Video',
      label: 'Video Details',
      icon: faVideo,
      content: <DealDetailsMeeting video={tabState.video} />
    },
    {
      name: 'Audio',
      label: 'Audio Details',
      icon: faClipboard,
      content: <DealDetailsNotes audio={tabState.audio} />
    },
    {
      name: 'Route',
      label: 'Route Details',
      icon: faMapPin,
      content: <RouteTracking />
    },
    {
      name: 'Image',
      label: 'Pri-Trip Image',
      icon: faImage,
      content: <DealDetailsAttachments />
    }
  ];

  useEffect(() => {
    setTabState(prevState => {
      const newState = { ...prevState };

      // Set all tabs to false
      Object.keys(newState).forEach(key => {
        newState[key as TabName] = false;
      });

      // Set the active tab to true
      newState[activeTab] = true;

      return newState;
    });
  }, [activeTab, setTabState]);

  return (
    <Tab.Container
      activeKey={activeTab}
      onSelect={(key: string | null) =>
        setActiveTab((key as TabName) || 'activity')
      }
    >
      <Nav
        variant="underline"
        className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
      >
        {tabitems.map(item => (
          <Nav.Item key={item.name} className="me-2 text-nowrap fdfgdfg">
            <Nav.Link eventKey={item.name.toLowerCase()}>
              <FontAwesomeIcon
                icon={item.icon}
                className="me-2 tab-icon-color"
              />
              {item.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabitems.map(item => (
          <Tab.Pane key={item.name} eventKey={item.name.toLowerCase()}>
            {item.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default DealDetailsTab;
