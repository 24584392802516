/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiRoutes } from 'common/api-routes';
import { ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { EventListProps } from '../ecommerce/dashboard.interface';
import { get_api } from 'apis';
import {
  EventUserProfilePageLimit,
  PageIndex,
  EventUserProfilePageSize
} from 'common/variables';
import { UserProfileProps } from './user.interface';
import { toast } from 'react-toastify';
import { Routes } from 'common/router-name';
import { useAppDispatch } from '../../../redux/hooks';

const useUserEventProfileHook = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [filter, setfilter] = useState({
    keyValue: '1',
    isActive: true
  });
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: EventUserProfilePageSize
  });
  const [search, setSearch] = useState('');
  const [handleSearch, setHandleSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortType, setSortType] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [audioLink, setAudioLink] = useState('');
  const dispatch = useAppDispatch();

  const { Dashboard_Two, EventDetailsPage } = Routes;
  const { common, events, userProfile } = ApiRoutes;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const fetchEvents = async (
    value1: string | undefined,
    value2: number,
    value3: number,
    value4: string = ''
  ): Promise<EventListProps> => {
    const url = `${common}${events}`;
    value2 = value2 + 1;
    value1 = value4 !== '' ? value4 : value1;
    const data = (await get_api(
      `${url}?search=${value1}&page=${value2}&page_size=${value3}`,
      dispatch
    )) as EventListProps;
    data.pages = Math.ceil(data.count / EventUserProfilePageLimit);
    return data;
  };

  const {
    data: userEvent,
    isLoading: IsLoadinguserEvent,
    isError: IsErroruserEvent,
    error: ErroruserEvent
  } = useQuery(
    ['user-event-list', slug, pagination, handleSearch],
    async () =>
      await fetchEvents(
        slug,
        pagination.pageIndex,
        pagination.pageSize,
        handleSearch
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchUserDetails = async (
    value1: string | undefined
  ): Promise<UserProfileProps | null> => {
    if (!value1) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = { data: { message: 'User not found' } } as any;
      throw error;
    }
    const url = `${common}${userProfile}`;
    try {
      const data = (await get_api(
        `${url}?email=${encodeURIComponent(value1)}`,
        dispatch
      )) as UserProfileProps;
      return data;
    } catch (error: any) {
      console.log('error: ', error?.data.email.toString());
      toast.error(error?.data.email.toString());
      navigate(Dashboard_Two);
      return null;
    }
  };

  const {
    data: details,
    isLoading: IsLoadingdetails,
    isError: IsErrordetails,
    error: Errordetails
  } = useQuery(
    ['user-details', slug],
    async () => await fetchUserDetails(slug),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value || '');
  };

  return {
    search,
    setSearch,
    sortBy,
    setSortBy,
    sortType,
    setSortType,
    userEvent,
    IsLoadinguserEvent,
    IsErroruserEvent,
    ErroruserEvent,
    filter,
    setfilter,
    pagination,
    setPagination,
    handleSearchInputChange,
    setHandleSearch,
    details,
    IsLoadingdetails,
    IsErrordetails,
    Errordetails,
    EventDetailsPage,
    videoLink,
    setVideoLink,
    audioLink,
    setAudioLink,
    show,
    handleClose,
    handleShow
  };
};

export default useUserEventProfileHook;
