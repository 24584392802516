/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch_api } from 'apis';
import { ApiRoutes } from 'common/api-routes';
import { Messages } from 'common/router-name';
import { toast } from 'react-toastify';

const useDealsOtherInformationHook = () => {
  const { common, events } = ApiRoutes;

  const handleUpdateTagComment = async (
    values: { tag: string; dispatcher_comment: string },
    eventId: string = ''
  ) => {
    if (!eventId) {
      toast.error('Invalid event id');
      return;
    }
    const url = `${common}${events}${eventId}/`;
    try {
      await patch_api(url, values);
      toast.success(Messages.COMMENT_SUCCESS);
    } catch (error: any) {
      // console.log('error?.data: ', error?.data);
      // console.log('error?.data?.phone_no[0]: ', error?.data?.phone_no[0]);
      // toast.error(error?.data?.email[0]);
      toast.error(Messages.COMMENT_ERROR);
    }
  };

  return {
    handleUpdateTagComment
  };
};

export default useDealsOtherInformationHook;
