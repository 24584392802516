import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import useUserEventProfileHook from '../user-events-profile/useUserEventProfileHook';

const AverageRatingCard = () => {
  const { details } = useUserEventProfileHook();
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Average User Rating</h5>
            <h6 className="text-body-tertiary">All Time</h6>
          </div>
        </div>
        <div className="w-100 h-75 d-flex gap-1 align-items-center justify-content-center">
          <p className="fs-1 fw-bold">{details?.avg_rating ?? 0.0}</p>
          <FontAwesomeIcon
            icon={faStar}
            size="2x"
            color="#f7ac16"
            className="me-2"
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default AverageRatingCard;
