import { Row } from 'react-bootstrap';
import EcomLatestReviewsTable from './EcomLatestReviewsTable';
import { Col } from 'react-bootstrap';

const PendingUserQueComponent = () => {
  return (
    <>
      <Row className="align-items-end justify-content-between pb-5 g-3">
        <Col xs="auto">
          <h3>Pending User Queue</h3>
          <p className="text-body-tertiary lh-sm mb-0">
            These are users that are waiting on you
          </p>
        </Col>
      </Row>
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EcomLatestReviewsTable />
      </div>
    </>
  );
};

export default PendingUserQueComponent;
