import Button from 'components/base/Button';
import SocialProfileCard from '../components/SocialProfileCard';
import SettingsChangePassword from '../components/SettingsChangePassword';
import SettingsCompanyInfo from '../components/SettingsCompanyInfo';
// import SettingsPersonalInfo from '../components/SettingsPersonalInfo';
import SocialSettingsSideBar from '../components/SocialSettingsSideBar';
import { Col, Row, Form as BForm, Modal } from 'react-bootstrap';
import profileImage from 'assets/img/icons/avatar.svg';
import useProfileHook from './useProfileHook';
import { ErrorMessage, Form, Formik } from 'formik';
import { faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const Profile = () => {
  const {
    initialAddProfileValues,
    validationAddProfileSchema,
    initialPasswordValues,
    validationPasswordSchema,
    handleSubmit,
    handleChangePasswordSubmit,
    memberProfile,
    IsLoadingMemberProfile,
    btnDisable,
    handleRequestToAdmin,
    handleDeleteAccount,
    personalInfoFormRef
  } = useProfileHook();

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  if (IsLoadingMemberProfile) {
    return <>Loading...</>;
  }

  return (
    <div className="mb-9">
      <Row className="g-6">
        <Col xs={12} xl={4}>
          <SocialProfileCard
            avatar={memberProfile?.profile_image ?? profileImage}
            className="mb-5"
            profileData={memberProfile}
          />
          <SocialSettingsSideBar memberProfile={memberProfile} />
        </Col>
        <Col xs={12} xl={8}>
          <div className="border-bottom mb-4">
            <Formik
              innerRef={personalInfoFormRef}
              initialValues={initialAddProfileValues}
              validationSchema={validationAddProfileSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, handleBlur, values }) => {
                return (
                  <Form>
                    <div className="mb-6">
                      <h4 className="mb-4">Personal Information</h4>
                      <Row className="g-3">
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="firstName"
                                name="first_name"
                                type="text"
                                placeholder="First Name"
                                className="form-icon-input"
                                value={values?.first_name ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="firstName"
                                className="form-icon-label text-body-tertiary"
                              >
                                FIRST NAME
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="lastName"
                                name="last_name"
                                type="text"
                                placeholder="Last Name"
                                className="form-icon-input"
                                value={values?.last_name ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="lastName"
                                className="form-icon-label text-body-tertiary"
                              >
                                LAST NAME
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faUser}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Enter your email"
                                className="form-icon-input"
                                value={values?.email ?? ''}
                                disabled={true}
                              />
                              <label
                                htmlFor="email"
                                className="form-icon-label text-body-tertiary"
                              >
                                ENTER YOUR EMAIL
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-icon-container">
                            <BForm.Floating>
                              <BForm.Control
                                id="phone"
                                name="phone_no"
                                type="tel"
                                placeholder="Enter your phone"
                                className="form-icon-input"
                                value={values?.phone_no ?? ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <label
                                htmlFor="phone"
                                className="form-icon-label text-body-tertiary"
                              >
                                ENTER YOUR PHONE
                              </label>
                            </BForm.Floating>
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="text-body fs-9 form-icon"
                            />
                          </div>
                          <ErrorMessage
                            name="phone_no"
                            component="div"
                            className="fs-9 fw-normal text-danger"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <Row className="gx-3 mb-6 gy-6 gy-sm-3">
              <Col xs={12} sm={6}>
                <SettingsCompanyInfo />
              </Col>
              <Col xs={12} sm={6}>
                <div className="d-flex justify-content-between">
                  <h4 className="mb-4">Change Password</h4>
                  <Button
                    type="button"
                    variant="link"
                    className="p-0"
                    style={{ display: 'contents' }}
                    onClick={() => personalInfoFormRef.current?.submitForm()}
                    disabled={btnDisable}
                  >
                    Confirm
                  </Button>
                </div>
                <Formik
                  initialValues={initialPasswordValues}
                  validationSchema={validationPasswordSchema}
                  onSubmit={handleChangePasswordSubmit}
                >
                  {({ handleChange, handleBlur, resetForm }) => (
                    <Form>
                      <SettingsChangePassword
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        handleSubmit={handleSubmit}
                      />

                      <div className="text-end mt-6">
                        <Button
                          type="reset"
                          variant="phoenix-secondary"
                          className="me-2"
                          onClick={() => resetForm()}
                        >
                          Cancel Changes
                        </Button>
                        <Button
                          type="submit"
                          variant="phoenix-primary"
                          disabled={btnDisable}
                        >
                          Save Information
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
          <Row className="gy-5">
            <Col xs={12} md={6}>
              <h4 className="text-body-emphasis">Request Admin Access</h4>
              <p className="text-body-tertiary">
                Request access to become an account admin
              </p>
              <Button
                variant="phoenix-warning"
                disabled={btnDisable}
                onClick={handleRequestToAdmin}
              >
                Request
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <h4 className="text-body-emphasis">Account Deletion</h4>
              <p className="text-body-tertiary">
                Delete your account (you can always make a new one)
              </p>
              <Button
                variant="phoenix-danger"
                disabled={btnDisable}
                onClick={handleShow}
              >
                Delete account
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>Are you sure want to delete account?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleDeleteAccount();
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile;
