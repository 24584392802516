import { useState } from 'react';

const useAppHook = () => {
  const [statusFlag, setStatusFlag] = useState(false);
  return {
    statusFlag,
    setStatusFlag
  };
};

export default useAppHook;
