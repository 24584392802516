import { Routes } from 'common/router-name';
import { BadgeBg } from 'components/base/Badge';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export type LatestReviewsTableDataType = {
  product?: string;
  productImage?: string;
  customer?: {
    name?: string;
    avatar?: string;
    variant?: 'image' | 'name';
    avatarPlaceholder?: boolean;
  };
  rating?: number;
  review?: string;
  status?: {
    title?: string;
    badgeBg?: BadgeBg;
    icon?: string;
  };
  time?: string;
  organization?: string;
  phone?: string;
};

export const latestReviewsTableData: LatestReviewsTableDataType[] = [];

const { Dashboard_Two, FilteredUserEvents } = Routes;

export const eventUserProfileBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Home',
    url: Dashboard_Two,
    active: false
  },
  {
    label: 'Event User Profile',
    active: true
  }
];

export const eventDetailPage: PageBreadcrumbItem[] = [
  {
    label: 'Home',
    url: Dashboard_Two,
    active: false
  },
  {
    label: 'All User Activity',
    url: FilteredUserEvents,
    active: false
  },
  {
    label: 'Activity Details',
    active: true
  }
];
