import { Col, Row } from 'react-bootstrap';
import useSearchHook from './useSearchHook';
import { SearchResult } from './search.interface';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';

const Search = () => {
  const {
    searchResult,
    IsLoadingsearchDetails,
    EventDetailsPage,
    searchPagination,
    setSearchPagination,
    MembersRoute,
    PendingUserQue,
    auth,
    UserEventsProfile
  } = useSearchHook();

  const table = useAdvanceTable({
    data: searchResult?.results ?? [],
    columns: [],
    // pageSize: searchResult?.results.length ?? 0,
    pageSize: 20,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination: setSearchPagination,
    pageCount: searchResult?.pages,
    manualPagination: true,
    state: {
      pagination: searchPagination
    }
  });

  if (IsLoadingsearchDetails) {
    return <>Loading...</>;
  }

  const renderLinkUrl = (module: string, id: string = '') => {
    if (module === 'Event') {
      return `${EventDetailsPage}/${id}`;
    }
    if (module === 'DispatcherCalls') {
      return `${PendingUserQue}?q=${id}`;
    }
    return auth.user_type === 'DS'
      ? `${UserEventsProfile}/${encodeURIComponent(id)}`
      : `${MembersRoute}?q=${id}`;
  };

  return (
    <>
      <Row className="pt-3 pb-5">
        <Col xs={12} xxl={6}>
          <h2 className="mb-2">Search Results</h2>
        </Col>
      </Row>
      <AdvanceTableProvider {...table}>
        <Row className="gap-0 row-gap-3 pb-3">
          {searchResult?.results.length ? (
            searchResult?.results.map((routes: SearchResult, index: number) => {
              return (
                <Col
                  key={`${routes.id}-${index}`}
                  xs={12}
                  sm={6}
                  className="d-flex flex-column gap-2"
                >
                  <h4 className="h4">{routes.model} Page</h4>
                  <Row
                    className="py-3 mx-0 border border-1 rounded"
                    style={{
                      height: '60px',
                      minHeight: '110px',
                      overflowY: 'auto'
                    }}
                  >
                    <Col xs={12} sm={10}>
                      {Object.values(routes.data).join(', ')}
                    </Col>
                    {(routes.model === 'DispatcherCalls' ||
                      routes.model === 'User' ||
                      routes.model === 'Event') && (
                      <Col
                        xs={12}
                        sm={2}
                        className="d-flex justify-content-end align-items-end fw-bold cursor-pointer"
                      >
                        {routes.model === 'DispatcherCalls' ? (
                          <Link
                            to={renderLinkUrl(
                              routes.model,
                              routes.data?.event__user__email
                            )}
                            style={{ color: '#9747FF' }}
                          >
                            Explore
                          </Link>
                        ) : (
                          <Link
                            to={renderLinkUrl(routes.model, routes.id)}
                            style={{ color: '#9747FF' }}
                          >
                            Explore
                          </Link>
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
              );
            })
          ) : (
            <Col>No search found</Col>
          )}
        </Row>
        <AdvanceTableFooter
          navBtn
          showViewAllBtn={false}
          count={searchResult?.count}
        />
      </AdvanceTableProvider>
    </>
  );
};

export default Search;
