import React from 'react';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import errorIllustration from 'assets/img/spot-illustrations/404-illustration.png';
import dark404illustrations from 'assets/img/spot-illustrations/dark_404-illustration.png';
import { Routes } from 'common/router-name';

const NewFeature = () => {
  const { Dashboard_Two } = Routes;
  return (
    <div className="px-3">
      <Row className="min-vh-100 flex-center p-5">
        <Col xs={12} xl={10} xxl={8}>
          <Row className="justify-content-center align-items-center g-5">
            <Col xs={12} lg={6} className="text-center order-lg-1">
              <img
                src={errorIllustration}
                alt=""
                width={400}
                className="img-fluid w-lg-100 d-dark-none"
              />
              <img
                src={dark404illustrations}
                alt=""
                width={540}
                className="img-fluid w-md-50 w-lg-100 d-light-none"
              />
            </Col>
            <Col xs={12} lg={6} className="text-center text-lg-start">
              <h2 className="text-body-secondary fw-bolder mb-3">
                Upcoming New features!
              </h2>
              <Button variant="primary" size="lg" as={Link} to={Dashboard_Two}>
                Go Home
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NewFeature;
