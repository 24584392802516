/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  VideoAudioPageLimit,
  VideoAudioPageSize,
  PageIndex
} from 'common/variables';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ActivityObject,
  EventCallProps,
  EventTripProps,
  UserDetailsProps
} from '../../user.interface';
import { ApiRoutes } from 'common/api-routes';
import { get_api } from 'apis';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useAppDispatch } from '../../../../../redux/hooks';
import { Routes } from 'common/router-name';

const useDetailsHook = () => {
  const dispatch = useAppDispatch();
  const { event_id } = useParams();
  const navigate = useNavigate();
  const [activityPagination, setActivityPagination] = useState({
    pageIndex: PageIndex,
    pageSize: VideoAudioPageSize
  });
  const [callPagination, setCallPagination] = useState({
    pageIndex: PageIndex,
    pageSize: VideoAudioPageSize
  });
  const [videoPagination, setVideoPagination] = useState({
    pageIndex: PageIndex,
    pageSize: VideoAudioPageSize
  });
  const [audioPagination, setAudioPagination] = useState({
    pageIndex: PageIndex,
    pageSize: VideoAudioPageSize
  });
  const [imagePagination, setImagePagination] = useState({
    pageIndex: PageIndex,
    pageSize: 1
  });
  const { common, events, media, activities, dispatcherCallList } = ApiRoutes;
  const [videoLink, setVideoLink] = useState('');
  const [audioLink, setAudioLink] = useState('');
  const [callLink, setCallLink] = useState('');
  const [tabState, setTabState] = useState({
    video: false,
    audio: false,
    activity: false,
    call: false,
    route: false,
    image: false
  });

  const fetchUserEventVideoDetails = async (
    value1: string | undefined,
    value2: number,
    value3: number
  ): Promise<EventTripProps | null> => {
    const url = `${common}${events}`;
    try {
      value2 = value2 + 1;
      const data = (await get_api(
        `${url}${value1}/${media}?media_type=EV,EVR&page=${value2}&page_size=${value3}`,
        dispatch
      )) as EventTripProps;
      data.pages = Math.ceil(data.count / VideoAudioPageLimit);
      return data;
    } catch (error: any) {
      toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: videoDetails,
    isLoading: IsLoadingvideoDetails,
    isError: IsErrorvideoDetails,
    error: ErrorvideoDetails
  } = useQuery(
    ['video-calls', event_id, videoPagination],
    async () =>
      await fetchUserEventVideoDetails(
        event_id,
        videoPagination.pageIndex,
        videoPagination.pageSize
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchUserEventAudioDetails = async (
    value1: string | undefined,
    value2: number,
    value3: number
  ): Promise<EventTripProps | null> => {
    const url = `${common}${events}`;
    try {
      value2 = value2 + 1;
      const data = (await get_api(
        `${url}${value1}/${media}?media_type=EA,EAR&page=${value2}&page_size=${value3}`,
        dispatch
      )) as EventTripProps;
      data.pages = Math.ceil(data.count / VideoAudioPageLimit);
      return data;
    } catch (error: any) {
      // toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: audioDetails,
    isLoading: IsLoadingaudioDetails,
    isError: IsErroraudioDetails,
    error: ErroraudioDetails
  } = useQuery(
    ['audio-calls', event_id, audioPagination],
    async () =>
      await fetchUserEventAudioDetails(
        event_id,
        audioPagination.pageIndex,
        audioPagination.pageSize
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchUserEventImageDetails = async (
    value1: string | undefined
  ): Promise<EventTripProps | null> => {
    const url = `${common}${events}`;
    try {
      const data = (await get_api(
        `${url}${value1}/${media}?media_type=POI,PRI`,
        dispatch
      )) as EventTripProps;
      return data;
    } catch (error: any) {
      // toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: imageDetails,
    isLoading: IsLoadingImageDetails,
    isError: IsErrorImageDetails,
    error: ErrorImageDetails
  } = useQuery(
    ['image-event', event_id],
    async () => await fetchUserEventImageDetails(event_id),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const fetchUserDetails = async (
    value1: string | undefined
  ): Promise<UserDetailsProps | null> => {
    const url = `${common}${events}`;
    try {
      const data = (await get_api(
        `${url}${value1}`,
        dispatch
      )) as UserDetailsProps;
      return data;
    } catch (error: any) {
      // toast.error(error?.data.detail);
      navigate(Routes.Dashboard_Two);
      return error;
    }
  };

  const {
    data: userDetails,
    isLoading: IsLoadingUserDetails,
    isError: IsErrorUserDetails,
    error: ErrorUserDetails,
    status
  } = useQuery(
    ['user-details', event_id],
    async () => await fetchUserDetails(event_id),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const usageMinutes = (totalMin: number, usedMin: number): number => {
    const totalMinutes = totalMin ?? 0;
    const usedMinutes = usedMin ?? 0;

    // Calculate usage as a decimal
    const usageDecimal = usedMinutes / totalMinutes ?? 0;

    // Calculate usage as a percentage
    const usagePercentage = usageDecimal * 100;
    return Number(usagePercentage.toFixed(2));
  };

  const fetchUserEventActivityDetails = async (
    value1: string | undefined,
    value2: number,
    value3: number
  ): Promise<ActivityObject | null> => {
    const url = `${common}${events}`;
    try {
      value2 = value2 + 1;
      const data = (await get_api(
        `${url}${value1}/${activities}?page=${value2}&page_size=${value3}`,
        dispatch
      )) as ActivityObject;
      data.pages = Math.ceil(data.count / VideoAudioPageLimit);
      return data;
    } catch (error: any) {
      // toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: activityDetails,
    isLoading: IsLoadingactivityDetails,
    isError: IsErroractivityDetails,
    error: ErroractivityDetails
  } = useQuery(
    ['activity', event_id, activityPagination],
    async () =>
      await fetchUserEventActivityDetails(
        event_id,
        activityPagination.pageIndex,
        activityPagination.pageSize
      ),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  const fetchUserEventCallDetails = async (
    value1: string | undefined
  ): Promise<EventCallProps[] | null> => {
    const url = `${common}${events}`;
    try {
      const data = (await get_api(
        `${url}${value1}/${dispatcherCallList}`,
        dispatch
      )) as EventCallProps[];
      // const data = [
      //   {
      //     call_id: '0d0a16ab-a508-4d58-bf24-5dc3cb248f3e',
      //     session_id: 'string',
      //     session_status: 'STARTED',
      //     session_name: 'string',
      //     call_started_at: '2019-09-24T14:15:22Z',
      //     call_ended_at: '2019-08-24T14:15:22Z',
      //     duration: 'string',
      //     dispatcher_joined: true,
      //     recording_link:
      //       'https://commondatastorage.googleapis.com/codeskulptor-assets/Epoq-Lepidoptera.ogg',
      //     dispatcher_user: 0,
      //     created_at: '2024-09-24T14:15:22Z',
      //     updated_at: '2024-09-24T14:15:22Z'
      //   }
      // ];
      return data;
    } catch (error: any) {
      // toast.error(error?.data.detail);
      return null;
    }
  };

  const {
    data: callDetails,
    isLoading: IsLoadingcallDetails,
    isError: IsErrorcallDetails,
    error: ErrorcallDetails
  } = useQuery(
    ['call_list', event_id, callPagination],
    async () => await fetchUserEventCallDetails(event_id),
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return {
    audioDetails,
    IsLoadingaudioDetails,
    IsErroraudioDetails,
    ErroraudioDetails,
    videoDetails,
    IsLoadingvideoDetails,
    IsErrorvideoDetails,
    ErrorvideoDetails,
    videoPagination,
    setVideoPagination,
    audioPagination,
    setAudioPagination,
    videoLink,
    setVideoLink,
    audioLink,
    setAudioLink,
    imageDetails,
    IsLoadingImageDetails,
    IsErrorImageDetails,
    ErrorImageDetails,
    imagePagination,
    setImagePagination,
    userDetails,
    IsLoadingUserDetails,
    IsErrorUserDetails,
    ErrorUserDetails,
    status,
    tabState,
    setTabState,
    event_id,
    usageMinutes,
    activityDetails,
    IsLoadingactivityDetails,
    IsErroractivityDetails,
    ErroractivityDetails,
    activityPagination,
    setActivityPagination,
    callDetails,
    IsLoadingcallDetails,
    IsErrorcallDetails,
    ErrorcallDetails,
    callPagination,
    setCallPagination,
    callLink,
    setCallLink
  };
};

export default useDetailsHook;
