import { Col, Row } from 'react-bootstrap';
import AverageRatingCard from '../components/AverageRatingCard';
import EventsTable from './events';
import CustomerProfileCard from './CustomerProfileCard';
import EcoimDefaultAddressCard from './EcoimDefaultAddressCard';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { eventUserProfileBreadcrumbItems } from './filterEventsData';

const UserEventsProfile = () => {
  return (
    <>
      <div className="pb-4">
        <Row className="g-4">
          <Col xs={12} xxl={6} className="px-0">
            <PageBreadcrumb items={eventUserProfileBreadcrumbItems} />
            <h2 className="mb-2">Event User Profile</h2>
            <h5 className="text-body-tertiary fw-semibold">
              This is where you can view event history for every user
            </h5>
          </Col>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6">
            <Row className="g-6">
              <Col xs={12} md={12} xxl={5}>
                <CustomerProfileCard />
              </Col>
              <Col xs={12} md={6} xxl={4}>
                <EcoimDefaultAddressCard />
              </Col>
              <Col xs={12} md={6} xxl={3}>
                <AverageRatingCard />
              </Col>
            </Row>
          </div>
        </Row>
      </div>
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EventsTable />
      </div>
    </>
  );
};

export default UserEventsProfile;
