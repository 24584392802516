import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';

const rootReducer = combineReducers({
  auth: authSlice
});

const makeStore = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware()
});

// Infer the type of makeStore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppStore = ReturnType<any>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export const storeData = makeStore.getState();

export default makeStore;
