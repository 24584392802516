/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'components/base/Button';
import Dropzone from 'components/base/Dropzone';
import ReactSelect from 'components/base/ReactSelect';
import { ErrorMessage, Form, Formik } from 'formik';
import { Col, Row, Form as BootForm, Accordion } from 'react-bootstrap';
import useMemberHook from './useMemberHook';

const AddMember = () => {
  const {
    validationAddMemberSchema,
    initialAddMemberValues,
    btnDisable,
    SelectOptions,
    handleSubmit,
    csvFile,
    setCsvFile,
    handleSubmitCsvImport,
    validationImportMemberSchema,
    initialImportMemberValues,
    cancelToRedirect,
    errorMembers,
    downloadCSV
  } = useMemberHook();
  return (
    <div>
      <h2 className="mb-5">Add A Member</h2>
      <Accordion className="border border-secondary border-2 rounded-3" flush>
        <Accordion.Item eventKey="0" className="px-4 py-3">
          <Accordion.Header>Invite a member</Accordion.Header>
          <Accordion.Body>
            <Formik
              initialValues={initialAddMemberValues}
              validationSchema={validationAddMemberSchema}
              onSubmit={values => {
                handleSubmit(values);
                // resetForm();
              }}
            >
              {({ handleChange, handleBlur, setFieldValue }) => (
                <Form>
                  <Row className="g-3">
                    <Col xs={12}>
                      <ReactSelect
                        placeholder="SELECT TYPE OF MEMBER"
                        options={SelectOptions}
                        id="role"
                        onChange={(option: any) => {
                          setFieldValue('role', option);
                        }}
                        onBlur={handleBlur}
                        closeMenuOnScroll={true}
                      />
                      <ErrorMessage
                        name="role.value"
                        component="div"
                        className="fs-9 fw-normal text-danger"
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="mb-0 text-start">
                        <BootForm.Control
                          id="firstName"
                          type="text"
                          placeholder="FIRST NAME"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="fs-9 fw-normal text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="mb-0 text-start">
                        <BootForm.Control
                          id="lastName"
                          type="text"
                          placeholder="LAST NAME"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="fs-9 fw-normal text-danger"
                        />
                      </div>
                    </Col>

                    <Col sm={12} md={6}>
                      <div className="mb-0 text-start">
                        <BootForm.Control
                          id="email"
                          type="email"
                          className="form-icon-input"
                          placeholder="EMAIL"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="fs-9 fw-normal text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <div className="mb-0 text-start">
                        <BootForm.Control
                          id="phone"
                          type="text"
                          className="form-icon-input"
                          placeholder="+00000000000"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="fs-9 fw-normal text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end gap-3 mb-3 mt-3">
                    <Button
                      type="button"
                      variant="phoenix-primary"
                      onClick={cancelToRedirect}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-25"
                      disabled={btnDisable}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="px-4 py-3">
          <Accordion.Header>Bulk invite members</Accordion.Header>
          <Accordion.Body>
            <Formik
              initialValues={initialImportMemberValues}
              validationSchema={validationImportMemberSchema}
              onSubmit={values => {
                handleSubmitCsvImport(values);
                // resetForm();
                setCsvFile(null);
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Row className="g-3">
                    <Col xs={12}>
                      <Dropzone
                        className="mb-3"
                        accept={{
                          'image/*': ['.csv']
                        }}
                        placeholder="Click to upload a CSV file to upload members Or Drag & Drop"
                        onDrop={acceptedFiles => {
                          setCsvFile(acceptedFiles[0]);
                          setFieldValue('file', acceptedFiles[0]);
                        }}
                        noPreview={true}
                      />
                      <ErrorMessage
                        name="file"
                        component="div"
                        className="fs-9 fw-normal text-danger"
                      />
                      <p>{csvFile?.name}</p>
                    </Col>
                  </Row>
                  {errorMembers.length > 0 && (
                    <span
                      className="text-info cursor-pointer"
                      onClick={() => downloadCSV(errorMembers)}
                      title="Click to download import csv result"
                    >
                      Click to download import csv result
                    </span>
                  )}
                  <div className="d-flex justify-content-end gap-3 mb-3 mt-3">
                    <Button
                      type="button"
                      variant="phoenix-primary"
                      onClick={cancelToRedirect}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="w-25"
                      disabled={btnDisable}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AddMember;
