import { Col, Row } from 'react-bootstrap';
import EcomTotalOrdersCard from '../components/EcomTotalOrdersCard';
import EcomNewCustomersCard from '../components/EcomNewCustomersCard';
import EcomTopCouponsCard from '../components/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from '../components/EcomPayingVsNonPayingCard';
import EventsTable from './events';
import useDashboardHook from '../ecommerce/useDashboardHook';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { filterEventBreadcrumbItems } from './filterEventsData';
import useFilteredUserEventHook from './useFilteredUserEventHook';

const Ecommerce = () => {
  const {
    callTime,
    isLoadingcallTime,
    usage,
    isLoadingusage,
    activeUser,
    isLoadingactiveUser,
    callProportion,
    isLoadingcallProportion
  } = useDashboardHook();
  const { qValue } = useFilteredUserEventHook();
  return (
    <>
      <div className="pb-1">
        <Row className="g-4">
          <Col xs={12} xxl={6} className="px-0">
            <PageBreadcrumb items={filterEventBreadcrumbItems} />
            <h2 className="mb-2">Filtered User Events</h2>
            <h5 className="text-body-tertiary fw-semibold">
              This is where you can view event history for every user
            </h5>
          </Col>
          <hr className="bg-body-secondary" />
        </Row>
      </div>
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EventsTable />
      </div>
      {!qValue && (
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-6 pb-9 border-top">
          <Row className="g-6">
            <Col xs={12} md={6} xxl={3}>
              <EcomTopCouponsCard data={usage} isLoading={isLoadingusage} />
            </Col>
            <Col xs={12} md={6} xxl={3}>
              <EcomTotalOrdersCard
                data={callTime}
                isLoading={isLoadingcallTime}
              />
            </Col>
            <Col xs={12} md={6} xxl={3}>
              <EcomNewCustomersCard
                data={activeUser}
                isLoading={isLoadingactiveUser}
                title="Friend Invitations"
              />
            </Col>
            <Col xs={12} md={6} xxl={3}>
              <EcomPayingVsNonPayingCard
                data={callProportion}
                isLoading={isLoadingcallProportion}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Ecommerce;
