import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

interface RestrictedDsProps {
  children: React.ReactNode;
}

const RestrictedDsRoutes: React.FC<RestrictedDsProps> = ({ children }) => {
  const userType = useAppSelector(state => state.auth.user.user_type);

  if (userType !== 'DS') {
    return <Navigate to="/not-found" />;
  }

  return <>{children}</>;
};

export default RestrictedDsRoutes;
