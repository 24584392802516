import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/base/AdvanceTable';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Row, Modal } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { EventProps } from '../ecommerce/dashboard.interface';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { Link } from 'react-router-dom';
import { Routes } from 'common/router-name';
import Rating from 'components/base/Rating';
import { PageSize } from 'common/variables';
import useFilteredUserEventHook from './useFilteredUserEventHook';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';

const EventsTable = () => {
  const {
    eventList,
    isLoadingeventList,
    formatTimestamp,
    pagination,
    setPagination
  } = useFilteredUserEventHook();

  const { EventDetailsPage, UserEventsProfile } = Routes;
  const domain = process.env.REACT_APP_URL;

  const [url, setURL] = useState('');
  const [copied, setCopied] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCopied(false);
    setURL('');
  };
  const handleShow = (url: string) => {
    setShow(true);
    setURL(url);
  };

  const handleCopy = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
      setCopied(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const columns: ColumnDef<EventProps>[] = [
    {
      id: 'productImage',
      accessorKey: '',
      meta: { cellProps: { className: 'py-0' } },
      enableSorting: false
    },
    {
      accessorKey: 'event',
      header: 'Event',
      cell: ({ row: { original } }) => {
        const { event_type, created_at, event_id } = original;
        return (
          <Link
            to={`${EventDetailsPage}/${event_id}`}
            className="d-flex align-items-center "
          >
            {`${event_type.slice(0, 46)}${event_type.length > 46 ? '...' : ''}`}{' '}
            {created_at &&
              ` on ${format(parseISO(created_at), 'MM/dd/yyyy HH:MM')}`}
          </Link>
        );
      },
      enableSorting: true,
      meta: {
        headerProps: { style: { minWidth: 200 }, className: 'py-2' }
      }
    },
    {
      accessorKey: 'name',
      header: 'USER',
      cell: ({ row: { original } }) => {
        const { first_name, last_name, email } = original;
        return (
          <Link
            to={`${UserEventsProfile}/${encodeURIComponent(email)}`}
            className="d-flex align-items-center "
          >
            {first_name} {last_name}
          </Link>
        );
      },
      meta: {
        headerProps: { style: { minWidth: 200 } }
      }
    },
    {
      accessorKey: 'organization',
      header: 'ORGANIZATION',
      cell: ({ row: { original } }) => {
        const { organization } = original;
        return <h6 className="mb-0 text-body">{organization ?? 'N/A'}</h6>;
      },
      meta: {
        headerProps: { style: { minWidth: 100 } }
      }
    },
    {
      accessorKey: 'phone',
      header: 'PHONE NUMBER',
      cell: ({ row: { original } }) => {
        const { phone_no } = original;
        return <h6 className="mb-0 text-body">{phone_no ?? 'N/A'}</h6>;
      },
      meta: {
        headerProps: { style: { minWidth: 100 } }
      }
    },
    {
      accessorKey: 'rating',
      header: 'RATING',
      cell: ({ row: { original } }) => {
        const { rating } = original;
        return <Rating iconClass="fs-10" readonly initialValue={rating ?? 0} />;
      },
      meta: {
        headerProps: { style: { minWidth: 110 } }
      }
    },
    {
      accessorKey: 'review',
      header: 'DISPATCHER COMMENTS',
      cell: ({ row: { original } }) => {
        const { dispatcher_comment } = original;
        return (
          <p className="fs--1 fw-semibold text-body-highlight mb-0 line-clamp-3">
            {dispatcher_comment ?? 'N/A'}
            {/* {dispatcher_comment.length > 134 && (
            <>
              {`...`}
              <Link to="#!">See more</Link>
            </>
          )} */}
          </p>
        );
      },
      meta: {
        headerProps: { style: { minWidth: 250 } }
      }
    },
    {
      accessorKey: '',
      header: 'TAGS',
      cell: ({ row: { original } }) => {
        const { tag } = original;
        return (
          // <Badge
          //   // bg={badgeBg}
          //   variant="phoenix"
          //   iconPosition="end"
          //   className="fs-10"
          //   // icon={<FeatherIcon icon={icon} size={12} className="ms-1" />}
          // >
          //   {tag ?? 'N/A'}
          // </Badge>
          <>{tag ?? 'N/A'}</>
        );
      },
      meta: {
        headerProps: { className: 'ps-5', style: { minWidth: 120 } },
        cellProps: { className: 'ps-5' }
      }
    },
    {
      accessorKey: 'time',
      header: 'TIME',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <h6 className="text-body-highlight mb-0">
            {formatTimestamp(created_at)}
          </h6>
        );
      },
      meta: {
        headerProps: { className: 'text-end', style: { minWidth: 100 } },
        cellProps: { className: 'text-end white-space-nowrap' }
      }
    },
    {
      accessorKey: 'action',
      enableSorting: false,
      header: '',
      cell: ({ row: { original } }) => {
        const { event_id } = original;
        return (
          <Button
            title="copy link"
            variant="phoenix-secondary"
            className="fs-10"
            size="sm"
            onClick={() =>
              handleShow(`${domain}${EventDetailsPage}/${event_id}`)
            }
          >
            <FontAwesomeIcon icon={faShareSquare} />
          </Button>
        );
      },
      meta: {
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: eventList?.results ?? [],
    columns,
    pageSize: PageSize,
    pagination: true,
    selection: true,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination,
    pageCount: eventList?.pages,
    manualPagination: true,
    state: {
      pagination
    }
  });

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Row className="align-items-end justify-content-between pb-5 g-3">
          <Col xs="auto">
            <h3>Events</h3>
            <p className="text-body-tertiary lh-sm mb-0">
              Gather insight on user level data
            </p>
          </Col>
        </Row>
        <AdvanceTableFooter
          navBtn
          showViewAllBtn={false}
          count={eventList?.count}
        />
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table fs-9 mb-0 border-top border-translucent'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
          isLoading={isLoadingeventList}
        />
      </AdvanceTableProvider>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>{url}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant={copied ? 'success' : 'primary'}
            onClick={() => handleCopy(url)}
            disabled={copied}
          >
            {copied ? 'Copied' : 'Copy URL'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventsTable;
