/* eslint-disable @typescript-eslint/no-explicit-any */
// // import Mapbox from 'components/base/MapBox';
// import { Col, Row } from 'react-bootstrap';
// // import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
// // import { LatLngExpression } from 'leaflet';
// // import 'leaflet/dist/leaflet.css';
// // import { useState } from 'react';
// // import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// // import { APIProvider, Map } from '@vis.gl/react-google-maps';

// const RouteTracking = () => {
//   // const API_KEY = 'AIzaSyBAV4bllfQ0N1-J2gYY4hcU1P_qAvd1d_I';
//   // Define your locations with latitude and longitude

//   // const startLocation = { lat: 28.7041, lng: 77.1025 }; // Example: New Delhi
//   // const destinationLocation = { lat: 28.5355, lng: 77.391 }; // Example: Noida
//   // const currentLocation = { lat: 28.6139, lng: 77.209 }; // Example: Somewhere in between

//   // const [routePath] = useState<LatLngExpression[]>([
//   //   [startLocation.lat, startLocation.lng],
//   //   [currentLocation.lat, currentLocation.lng],
//   //   [destinationLocation.lat, destinationLocation.lng]
//   // ]);

//   // Define the path to highlight (array of lat-lng pairs)
//   // const path: LatLngExpression[] = [
//   //   startLocation,
//   //   currentLocation,
//   //   destinationLocation
//   // ];
//   // const defaultCenter = {
//   //   lat: 40.73061, // Example: New York latitude
//   //   lng: -73.935242 // Example: New York longitude
//   // };

//   // const mapContainerStyle = {
//   //   width: '100%',
//   //   height: '400px'
//   // };

//   return (
//     <Row className="align-items-center gx-4 gy-3 flex-wrap mb-3">
//       <Col sm="auto" className="flex-1">
//         <h2 className="mb-0">Route</h2>
//       </Col>
//       {/* <Row className="gy-9 gx-5">
//         <Col xs={12}>
//           <LoadScript googleMapsApiKey={API_KEY}>
//             <GoogleMap
//               mapContainerStyle={mapContainerStyle}
//               zoom={10} // Default zoom level
//               center={defaultCenter} // Center of the map
//             >
//               <Marker position={defaultCenter} />
//             </GoogleMap>
//           </LoadScript>
//           <APIProvider apiKey="AIzaSyBvYTHyEu6DwTaDk0tAN9d_Nl49o4LjNOA">
//             <Map
//               style={{ width: '100vw', height: '100vh' }}
//               defaultCenter={{ lat: 22.54992, lng: 0 }}
//               defaultZoom={3}
//               gestureHandling={'greedy'}
//               disableDefaultUI={true}
//             />
//           </APIProvider>
//           <MapContainer
//             center={[currentLocation.lat, currentLocation.lng]}
//             zoom={13}
//             style={{ height: '100vh', width: '100%' }}
//           >
//             <TileLayer
//               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//             />
//             <Marker position={[startLocation.lat, startLocation.lng]} />
//             <Marker
//               position={[destinationLocation.lat, destinationLocation.lng]}
//             />
//             <Marker position={[currentLocation.lat, currentLocation.lng]} />
//             <Polyline positions={routePath} color="blue" weight={6} />
//           </MapContainer>
//         </Col>
//       </Row> */}
//     </Row>
//   );
// };

// export default RouteTracking;

import React from 'react';
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

// const containerStyle = {
//   width: '400px',
//   height: '400px'
// };

// const center = {
//   lat: -3.745,
//   lng: -38.523
// };

const RouteTracking = () => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyBAV4bllfQ0N1-J2gYY4hcU1P_qAvd1d_I'
  // });
  // const [map, setMap] = React.useState<any>(null);
  // console.log('map: ', map);
  // const onLoad = React.useCallback(function callback(map: any) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);
  // const onUnmount = React.useCallback(function callback(map: any) {
  //   console.log('map: ', map);
  //   setMap(null);
  // }, []);
  // return isLoaded ? (
  //   <GoogleMap
  //     mapContainerStyle={containerStyle}
  //     center={center}
  //     zoom={10}
  //     onLoad={onLoad}
  //     onUnmount={onUnmount}
  //   >
  //     {/* Child components, such as markers, info windows, etc. */}
  //     <></>
  //   </GoogleMap>
  // ) : (
  //   <></>
  // );
  return (
    <>
      <h2>Routes</h2>
    </>
  );
};

// export default React.memo(RouteTracking);
export default RouteTracking;
