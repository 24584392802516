/* eslint-disable no-useless-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { get_api, post_api } from 'apis';
import { ApiRoutes } from '../../common/api-routes';
import {
  AuthResponseSuccessProps,
  credentialsProps,
  loggedInUserProps
} from 'interfaces/auth.interface';
import { toast } from 'react-toastify';

const { common, login } = ApiRoutes;

const loginApi = async (credentials: credentialsProps) => {
  try {
    const url = `${common}${login}`;
    const response = (await post_api(
      url,
      credentials
    )) as AuthResponseSuccessProps;
    return response;
  } catch (error: any) {
    toast.error(error?.data?.error);
    throw error;
  }
};

const logoutApiCalling = async () => {
  // let mainUrl = process.env.NEXT_PUBLIC_MAIN_URL;
  // mainUrl = `${mainUrl}auth/logout`;
  // const data = { refreshToken: token };
  // try {
  //   await axios.post(mainUrl, data);
  //   toast.success("Logout successfully")
  // } catch (error: any) {
  //   toast.error(error?.data?.message)
  //   throw error;
  // }
};

const getLoggedInUser = async ({ id, token }: loggedInUserProps) => {
  // const dispatch = useAppDispatch();
  try {
    const response = await get_api(`users/${id}`, null, token);
    return response?.data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export { loginApi, getLoggedInUser, logoutApiCalling };
