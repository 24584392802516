// import Badge from 'components/base/Badge';
import EcomNewCustomersChart from './EcomNewCustomersChart';
import { Card } from 'react-bootstrap';
import { allActiveUserProps } from '../ecommerce/dashboard.interface';

interface EcomNewCustomersCardProps {
  data: allActiveUserProps | undefined;
  isLoading: boolean;
  title?: string;
}

const EcomNewCustomersCard = ({
  data,
  isLoading,
  title = 'New active users'
}: EcomNewCustomersCardProps) => {
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">
              {title}
              {/* <Badge bg="warning" variant="phoenix" pill className="ms-2">
                +26.5%
              </Badge> */}
            </h5>
            <h6 className="text-body-tertiary">Last 7 days</h6>
          </div>
          <h4>{data?.currentWeek.total ?? 0}</h4>
        </div>
        <div className="pb-0 pt-4">
          <EcomNewCustomersChart data={data} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomNewCustomersCard;
