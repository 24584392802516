import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

interface RestrictedProps {
  children: React.ReactNode;
}

const RestrictedRoutes: React.FC<RestrictedProps> = ({ children }) => {
  const userType = useAppSelector(state => state.auth.user.user_type);

  if (userType !== 'DA') {
    return <Navigate to="/not-found" />;
  }

  return <>{children}</>;
};

export default RestrictedRoutes;
