export const Limit = 6;
export const PageSize = 6;
export const PageIndex = 0;
export const Page = 1;
export const VideoAudioPageLimit = 6;
export const VideoAudioPageSize = 6;
export const EventUserProfilePageSize = 9;
export const EventUserProfilePageLimit = 9;
export const VideoType = ['EV', 'EVR'];
export const AudioType = ['EA', 'EAR'];
