/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';
import { CSSProperties } from 'react';

interface AdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
}

const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter,
  isLoading,
  style
}: AdvanceTableProps) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders, getFooterGroups } = table;

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr className={rowClassName}>
          <td colSpan={getFlatHeaders().length}>Loading...</td>
        </tr>
      );
    }
    return getRowModel().rows.length > 0 ? (
      getRowModel().rows.map(row => (
        <tr key={row.id} className={rowClassName}>
          {row.getVisibleCells().map(cell => (
            <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))
    ) : (
      <tr className={rowClassName}>
        <td colSpan={getFlatHeaders().length}>No records found</td>
      </tr>
    );
  };

  return (
    <div className="scrollbar ms-n1 ps-1" style={style}>
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {getFlatHeaders().map(header => {
              return (
                <th
                  key={header.id}
                  {...header.column.columnDef.meta?.headerProps}
                  className={classNames(
                    header.column.columnDef.meta?.headerProps?.className,
                    {
                      sort: header.column.getCanSort(),
                      desc: header.column.getIsSorted() === 'desc',
                      asc: header.column.getIsSorted() === 'asc'
                    }
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {/* {getRowModel().rows.length > 0 ? (
            getRowModel().rows.map(row => (
              <tr key={row.id} className={rowClassName}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} {...cell.column.columnDef.meta?.cellProps}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className={rowClassName}>
              <td colSpan={getFlatHeaders().length}>No records found</td>
            </tr>
          )} */}
          {renderTableBody()}
        </tbody>

        {hasFooter && (
          <tfoot>
            {getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id} className="border-0 border-translucent">
                {footerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...header.column.columnDef.meta?.footerProps}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default AdvanceTable;
