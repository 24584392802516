import React from 'react';
import { Form } from 'react-bootstrap';
import useProfileHook from '../profile/useProfileHook';
import { ProfileComponentProps } from '../profile/profile.interface';

const SocialSettingsSideBar = ({ memberProfile }: ProfileComponentProps) => {
  const { handleCheckboxChange, setting: s1 } = useProfileHook();
  return (
    <>
      <div className="border-bottom border-translucent border-dashed pb-3 mb-4">
        <Form.Check type="checkbox" id="auto_logout">
          <Form.Check.Input
            type="checkbox"
            name="auto_logout"
            className="cursor-pointer"
            checked={s1?.auto_logout ?? memberProfile?.auto_logout ?? false}
            onChange={handleCheckboxChange}
          />
          <Form.Check.Label
            htmlFor="auto_logout"
            className="fs-8 fst-normal cursor-pointer"
          >
            Auto logout after 20 minutes of inactivity
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="checkbox" id="receive_crimesly_updates">
          <Form.Check.Input
            type="checkbox"
            name="receive_crimesly_updates"
            className="cursor-pointer"
            checked={
              s1?.receive_crimesly_updates ??
              memberProfile?.receive_crimesly_updates ??
              false
            }
            onChange={handleCheckboxChange}
          />
          <Form.Check.Label
            htmlFor="receive_crimesly_updates"
            className="fs-8 fst-normal cursor-pointer"
          >
            Receive Crimesly corporate updates
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="checkbox" id="password_change_reminder">
          <Form.Check.Input
            type="checkbox"
            name="password_change_reminder"
            className="cursor-pointer"
            checked={
              s1?.password_change_reminder ??
              memberProfile?.password_change_reminder ??
              false
            }
            onChange={handleCheckboxChange}
          />
          <Form.Check.Label
            htmlFor="password_change_reminder"
            className="fs-8 fst-normal cursor-pointer"
          >
            Send me reminders about required password changes
          </Form.Check.Label>
        </Form.Check>
      </div>
      <div className="mb-4">
        <Form.Check type="checkbox" id="login_2fa" className="form-switch">
          <Form.Check.Input
            type="checkbox"
            name="login_2fa"
            className="cursor-pointer"
            checked={s1?.login_2fa ?? memberProfile?.login_2fa ?? false}
            onChange={handleCheckboxChange}
          />
          <Form.Check.Label
            htmlFor="login_2fa"
            className="fs-8 fst-normal cursor-pointer"
          >
            Login 2FA
          </Form.Check.Label>
        </Form.Check>
        <Form.Check
          type="checkbox"
          id="email_notification"
          className="form-switch"
        >
          <Form.Check.Input
            type="checkbox"
            name="email_notification"
            className="cursor-pointer"
            checked={
              s1?.email_notification ??
              memberProfile?.email_notification ??
              false
            }
            onChange={handleCheckboxChange}
          />
          <Form.Check.Label
            htmlFor="email_notification"
            className="fs-8 fst-normal cursor-pointer"
          >
            Email notifications
          </Form.Check.Label>
        </Form.Check>
      </div>
    </>
  );
};

export default SocialSettingsSideBar;
