import EcomTopCouponsChart from './EcomTopCouponsChart';
import { Card } from 'react-bootstrap';
import { totalUsageProps } from '../ecommerce/dashboard.interface';

interface ecomTopCouponsCardProps {
  data: totalUsageProps | undefined;
  isLoading: boolean;
}

const EcomTopCouponsCard = ({ data, isLoading }: ecomTopCouponsCardProps) => {
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Usage</h5>
            <h6 className="text-body-tertiary">Last 7 days</h6>
          </div>
        </div>
        <div className="pb-4 pt-3">
          <EcomTopCouponsChart data={data} />
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">Open and used</h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.open_and_used ?? 0}%
            </h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-lighter me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Opened and didn’t use
            </h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.open_and_not_used ?? 0}%
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-info-dark me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">Have not used</h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.not_opened ?? 0}%
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomTopCouponsCard;
