import { Card, Col, Row, Modal } from 'react-bootstrap';
import avatar from 'assets/img/icons/avatar.svg';
import battery0 from 'assets/img/icons/battery_0.svg';
import battery25 from 'assets/img/icons/battery_25.svg';
import battery50 from 'assets/img/icons/battery_50.svg';
import battery75 from 'assets/img/icons/battery_75.svg';
import battery100 from 'assets/img/icons/battery_100.svg';
import bluetooth from 'assets/img/icons/bluetooth.svg';
import car from 'assets/img/icons/car.svg';
import useUserEventProfileHook from './useUserEventProfileHook';
import { formatTimestamp, getSixDaysBeforeDate } from 'helpers/date-flter';
import Button from 'components/base/Button';
import { useAppSelector } from '../../../redux/hooks';
import { AuthUserState } from '../../../redux/features/authSlice';

const CustomerProfileCard = () => {
  const authUser = useAppSelector(state => state.auth.user) as AuthUserState;
  const { details, IsLoadingdetails, show, handleClose, handleShow } =
    useUserEventProfileHook();

  const renderBattery = (value: number | string) => {
    if (typeof value === 'string') {
      return battery0;
    }
    if (value <= 100 && value > 90) return battery100;
    else if (value <= 90 && value > 60) return battery75;
    else if (value <= 60 && value > 20) return battery50;
    else if (value <= 20 && value > 0) return battery25;
    else return battery0;
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body className="pb-3">
          {authUser.user_type === 'DA_T' && (
            <div className="d-flex justify-content-end">
              <Button
                variant="danger"
                type="button"
                style={{ backgroundColor: '#EEA1A1' }}
                onClick={handleShow}
              >
                DELETE USER ACCOUNT
              </Button>
            </div>
          )}
          <Row className="d-flex justify-content-around align-items-center g-5 mt-2 mb-3 text-center text-sm-start">
            <Col xs={12} sm="auto" className="mb-sm-3 mt-0">
              <div className="w-25">
                <img
                  className="d-inline-flex rounded-pill"
                  width={72}
                  height={72}
                  src={details?.profile_image ?? avatar}
                />
              </div>
            </Col>
            <Col xs={12} sm="auto" className="flex-1">
              {IsLoadingdetails ? (
                <>Loading...</>
              ) : (
                <>
                  <h3>{`${details?.first_name ?? ''} ${
                    details?.last_name ?? ''
                  }`}</h3>
                  <p className="text-body-secondary">
                    User since&nbsp;
                    {details?.created_at
                      ? getSixDaysBeforeDate(details?.created_at, 'yes', true)
                      : 'N/A'}
                  </p>
                  <Row>
                    <Col xs={4} xl={6}>
                      <div className="d-flex gap-2 justify-content-start">
                        <img src={renderBattery(details?.battery ?? 0)} />
                        <span>
                          {details?.battery &&
                          typeof details?.battery !== 'string'
                            ? details?.battery + '%'
                            : 'N/A'}
                        </span>
                      </div>
                    </Col>
                    <Col xs={4} xl={6}>
                      <div className="d-flex gap-2 justify-content-start">
                        <img src={car} />
                        <span>
                          {details?.speed
                            ? details?.speed.replace(/"/g, '')
                            : 'N/A'}
                        </span>
                      </div>
                    </Col>
                    <Col xs={4} xl={12}>
                      <div className="d-flex gap-2 justify-content-start">
                        <img src={bluetooth} />
                        <span>
                          {details?.bluetooth_connections &&
                          details?.bluetooth_connections.length &&
                          typeof details?.bluetooth_connections !== 'string'
                            ? details?.bluetooth_connections[0]
                            : 'N/A'}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          <div className="d-flex flex-between-center border-top border-dashed pt-4">
            <div>
              <h6>Total Calls</h6>
              <p className="fs-7 text-body-secondary mb-0">
                {details?.total_calls ?? 'N/A'}
              </p>
            </div>
            <div className="">
              <h6 className="text-right">Last Call</h6>
              <p className="fs-7 text-body-secondary mb-0">
                {details?.last_call
                  ? formatTimestamp(details?.last_call.call_started_at)
                  : 'N/A'}
              </p>
            </div>
            <div className="">
              <h6 className="text-right">Total Invites</h6>
              <p className="fs-7 text-body-secondary mb-0">
                {details?.total_friend_requests ?? 'N/A'}
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>Delete Account</Modal.Header>
        <Modal.Body>
          <div className="h4 mb-3">
            Are you sure you want to delete Crimesly app account?
          </div>
          <div className="fst-italic h6 text-danger">
            All data related to this account will be permanently deleted.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerProfileCard;
