/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EmailRegex, PhoneWithCountryRegex } from 'common/regex';
// import { membersTablecolumns } from 'components/tables/MembersTable';
// import { members } from './membersData';
// import useAdvanceTable from 'hooks/useAdvanceTable';
import { ChangeEvent, useState } from 'react';
import * as Yup from 'yup';
import { MemberListProps, MemberRoot } from './member.interface';
import { get_api, post_api } from 'apis';
import { toast } from 'react-toastify';
import { Messages, Routes } from 'common/router-name';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiRoutes } from 'common/api-routes';
import { useQuery } from 'react-query';
import { parseISO, format } from 'date-fns';
import { Limit, PageIndex, PageSize } from 'common/variables';
import { useAppDispatch } from '../../../redux/hooks';

const useMemberHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('q') ?? '';
  const [btnDisable, setBtnDisable] = useState(false);
  const { MembersRoute } = Routes;
  const { addMember, common, allMembers, importMember } = ApiRoutes;
  const [csvFile, setCsvFile] = useState<any>(null);
  const [errorMembers, setErrorMembers] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>(query ?? '');
  const [pagination, setPagination] = useState({
    pageIndex: PageIndex,
    pageSize: PageSize
  });
  const dispatch = useAppDispatch();

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const SelectOptions = [
    { value: 'DA', label: 'Dispatcher Admin' },
    { value: 'DS', label: 'Dispatcher Standard' }
  ];

  const validationAddMemberSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required')
      .matches(EmailRegex, 'Invalid email address'),
    phone: Yup.string()
      .trim()
      .required('Phone number is required')
      .matches(PhoneWithCountryRegex, 'Invalid phone number'),
    role: Yup.object({
      value: Yup.string().required('Type is required'),
      label: Yup.string().required('Label is required')
    }).required('Type is required')
  });

  const initialAddMemberValues: MemberRoot = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: {
      value: '',
      label: ''
    }
  };

  const validationImportMemberSchema = Yup.object().shape({
    file: Yup.mixed()
      .required('A file is required')
      .test(
        'fileFormat',
        'Unsupported Format',
        (value: any) => value && ['text/csv'].includes(value?.type)
      )
  });

  const initialImportMemberValues = {
    file: null
  };

  const handleSubmit = async (values: MemberRoot) => {
    setBtnDisable(true);
    const data = {
      user_type: values?.role?.value,
      first_name: values?.firstName,
      last_name: values?.lastName,
      email: values?.email,
      phone_no: values?.phone
    };
    const url = `${common}${addMember}`;
    try {
      await post_api(url, data, dispatch);
      toast.success(Messages.MEMBER_SUCCESS);
      navigate(`${MembersRoute}`);
      setBtnDisable(false);
    } catch (error: any) {
      setBtnDisable(false);
      toast.error(error?.data?.detail);
      toast.error(error?.data?.email && error?.data?.email.toString());
      toast.error(error?.data?.phone_no && error?.data?.phone_no.toString());
    }
  };

  const handleSubmitCsvImport = async (values: any) => {
    setBtnDisable(true);
    const formData = new FormData();
    formData.append('file', values.file);
    const url = `${common}${importMember}`;
    try {
      const response = await post_api(url, formData, dispatch);
      const errorMembersData = response?.filter(
        (data: any) => data.comment !== 'Success'
      );
      setErrorMembers(errorMembersData);
      toast.success(Messages.MEMBER_SUCCESS);
      setBtnDisable(false);
    } catch (error: any) {
      toast.error(error?.data?.detail);
      toast.error(error?.data?.file.toString());
      setBtnDisable(false);
    }
  };

  const fetchMembers = async (
    value: string = '',
    value2: number,
    value3: number
  ): Promise<MemberListProps | undefined> => {
    try {
      const url = `${common}${allMembers}`;
      value2 = value2 + 1;
      const data = (await get_api(
        `${url}?search=${value}&page=${value2}&page_size=${value3}`,
        dispatch
      )) as MemberListProps;
      data.pages = Math.ceil(data.count / Limit);
      return data;
    } catch (error: any) {
      toast.error(error?.data?.detail);
      return undefined;
    }
  };

  const {
    data: memberList,
    isLoading: isLoadingmember,
    isError: isErrormember,
    error: errormember
  } = useQuery(
    ['member-list', searchValue, pagination],
    async () =>
      await fetchMembers(
        searchValue,
        pagination.pageIndex,
        pagination.pageSize
      ),
    {
      refetchOnWindowFocus: false, // Disable refetching on window focus
      retry: false // Disable retries on failure
    }
  );

  const cancelToRedirect = () => {
    navigate(`${MembersRoute}`);
  };

  const convertArrayToCSV = (array: any[]) => {
    const header = Object.keys(array[0]).join(',');
    const rows = array
      .map((item: any) => Object.values(item).join(','))
      .join('\n');
    return `${header}\n${rows}`;
  };

  const downloadCSV = (data: any[]) => {
    const csvData = convertArrayToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const joinedAt = (timestamp: string): string => {
    const date = parseISO(timestamp);
    return format(date, 'MMM dd, h:mm a');
  };

  const formatTimestamp = (timestamp: string): string => {
    const date = parseISO(timestamp);
    const now = new Date();

    const differenceInMinutes = (now.getTime() - date.getTime()) / (1000 * 60);

    if (differenceInMinutes < 1) {
      return 'Now';
    } else if (differenceInMinutes < 60) {
      return `${Math.floor(differenceInMinutes)} minutes ago`;
    } else if (differenceInMinutes < 1440) {
      return `${Math.floor(differenceInMinutes / 60)} hours ago`;
    } else {
      return format(date, 'MMM dd, h:mm a');
    }
  };

  return {
    handleSearchInputChange,
    SelectOptions,
    btnDisable,
    validationAddMemberSchema,
    initialAddMemberValues,
    setBtnDisable,
    handleSubmit,
    memberList,
    isLoadingmember,
    isErrormember,
    errormember,
    csvFile,
    setCsvFile,
    handleSubmitCsvImport,
    validationImportMemberSchema,
    initialImportMemberValues,
    navigate,
    MembersRoute,
    cancelToRedirect,
    errorMembers,
    downloadCSV,
    searchValue,
    joinedAt,
    formatTimestamp,
    pagination,
    setPagination
  };
};

export default useMemberHook;
