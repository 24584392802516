import React from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/icons/avatar.svg';
import battery0 from 'assets/img/icons/battery_0.svg';
import battery25 from 'assets/img/icons/battery_25.svg';
import battery50 from 'assets/img/icons/battery_50.svg';
import battery75 from 'assets/img/icons/battery_75.svg';
import battery100 from 'assets/img/icons/battery_100.svg';
import bluetooth from 'assets/img/icons/bluetooth.svg';
import car from 'assets/img/icons/car.svg';
import Badge from 'components/base/Badge';
import useDetailsHook from './deals-details/useDetailsHook';
import dayjs from 'dayjs';

const DealProfileCard = ({ className }: { className?: string }) => {
  const { userDetails, IsLoadingUserDetails, usageMinutes } = useDetailsHook();

  if (IsLoadingUserDetails) {
    return <>Loading...</>;
  }

  const renderBattery = (value: number) => {
    if (value <= 100 && value > 90) return battery100;
    else if (value <= 90 && value > 60) return battery75;
    else if (value <= 60 && value > 20) return battery50;
    else if (value <= 20 && value > 0) return battery25;
    else return battery0;
  };

  return (
    <Card className={className}>
      <Card.Body>
        <Row className="align-items-center g-3">
          <Col sm="auto" className="flex-1">
            <h3 className="fw-bolder line-clamp-1 mb-4">User Profile</h3>
            <Row className="d-flex mb-4">
              <Col xs={6} xl={6}>
                <div className="d-xl-block align-items-center justify-content-between mb-5">
                  <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                    <Avatar
                      size="xl"
                      src={userDetails?.student.profile_image ?? avatar}
                      className="me-3"
                    />
                    <div>
                      <h5>
                        {userDetails?.student.first_name}
                        {userDetails?.student.last_name}
                      </h5>
                      <span>{userDetails?.student.user_type ?? 'N/A'}</span>
                    </div>
                  </div>
                  <Badge
                    variant="phoenix"
                    bg="secondary"
                    className="text-wrap py-1"
                  >
                    {userDetails?.student.organization ?? 'N/A'}
                  </Badge>
                </div>
              </Col>
              <Col xs={6} xl={6}>
                <Row className="gap-2">
                  <Col xs={12} xl={12}>
                    <div className="d-flex gap-2 justify-content-end">
                      <span>
                        {userDetails?.battery
                          ? userDetails?.battery + '%'
                          : '0'}
                      </span>
                      <img src={renderBattery(userDetails?.battery ?? 0)} />
                    </div>
                  </Col>
                  <Col xs={12} xl={12}>
                    <div className="d-flex gap-2 justify-content-end">
                      <span>
                        {userDetails?.bluetooth_connections &&
                        userDetails?.bluetooth_connections.length
                          ? userDetails?.bluetooth_connections[0]
                          : 'N/A'}
                      </span>
                      <img src={bluetooth} />
                    </div>
                  </Col>
                  <Col xs={12} xl={12}>
                    <div className="d-flex gap-2 justify-content-end">
                      <span>
                        {userDetails?.speed
                          ? userDetails?.speed.replace(/"/g, '')
                          : 'N/A'}
                      </span>
                      <img src={car} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ProgressBar
              variant="primary-lighter"
              now={usageMinutes(
                userDetails?.student.call_minutes_consumed_in_current_month ??
                  0,
                userDetails?.student.allowed_call_minutes_per_month ?? 0
              )}
              className="mb-2"
            />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">
                {userDetails?.student.call_minutes_consumed_in_current_month ??
                  0}{' '}
                out of{' '}
                {userDetails?.student.allowed_call_minutes_per_month ?? 0}{' '}
                minutes used
              </p>
              <div>
                <FeatherIcon
                  icon="clock"
                  className="lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">
                  {dayjs(userDetails?.updated_at).format('MMMM YYYY')}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DealProfileCard;
