/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'react-bootstrap';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ColumnDef } from '@tanstack/react-table';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { Activity } from '../../user.interface';
import dayjs from 'dayjs';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useDetailsHook from './useDetailsHook';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { activityType } from 'sitemap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const DealDetailsActivity = () => {
  const {
    activityDetails,
    IsLoadingactivityDetails,
    activityPagination,
    setActivityPagination
  } = useDetailsHook();

  /**
   * EVENT_STARTED
     EVENT_ENDED
     DISPATCHER_CALL_STARTED
     DISPATCHER_CALL_ENDED
     AUDIO_RECORDING_START
     AUDIO_RECORDING_END
     VIDEO_RECORDING_START
     VIDEO_RECORDING_END
     NAVIGATION_START
     DESTINATION_REACHED
     NAVIGATION_END
     NAVIGATION_END_INACTIVITY
   */

  const columns: ColumnDef<Activity>[] = [
    {
      header: 'Title',
      cell: ({ row: { original } }) => {
        const { activity_type } = original;
        return (
          <span className="fw-bold cursor-pointer d-flex align-items-center gap-3">
            {activityType[activity_type]?.name && (
              <div
                className="d-flex justify-content-center"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  alignItems: 'center',
                  color: activityType[activity_type]?.color ?? '',
                  backgroundColor: activityType[activity_type]?.bgColor ?? '',
                  rotate: activityType[activity_type]?.rotate ?? ''
                }}
              >
                <FontAwesomeIcon
                  icon={activityType[activity_type]?.icon ?? ('' as IconProp)}
                  transform={{ size: 24 }}
                />
              </div>
            )}
            {activityType[activity_type]?.name ?? 'N/A'}
          </span>
        );
      },
      enableSorting: false,
      meta: {
        headerProps: { style: { display: 'none', minWidth: 250 } }
      }
    },
    {
      header: 'Title',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <span className="fw-semibold cursor-pointer d-flex justify-content-end gap-2">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="ms-1"
              style={{ color: '#3874FF', fontSize: '16px' }}
            />
            <>{dayjs(created_at).format('D MMMM, YYYY, h:mma')}</>
          </span>
        );
      },
      enableSorting: false,
      meta: {
        headerProps: { style: { display: 'none' } }
      }
    }
  ];

  const table = useAdvanceTable({
    data: activityDetails?.results ?? [],
    columns,
    pageSize: 6,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true,
    setPagination: setActivityPagination,
    pageCount: activityDetails?.pages,
    manualPagination: true,
    state: {
      pagination: activityPagination
    }
  });

  if (IsLoadingactivityDetails) {
    return <>Loading...</>;
  }
  return (
    <>
      <h2 className="mb-4">Activity</h2>
      <Row className="g-3">
        <Col xl={12} xxl={12} className="d-flex flex-column gap-3">
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              tableProps={{
                className:
                  'phoenix-table fs-9 mb-0 border-top border-translucent'
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
              isLoading={IsLoadingactivityDetails}
            />
            <AdvanceTableFooter
              navBtn
              showViewAllBtn={false}
              count={activityDetails?.count}
            />
          </AdvanceTableProvider>
        </Col>
      </Row>
    </>
  );
};

export default DealDetailsActivity;
