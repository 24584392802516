interface ApiRoutesInterface {
  common: string;
  register: string;
  login: string;
  sendOTP: string;
  verifyOTP: string;
  resetPassword: string;
  userCount: string;
  totalCalls: string;
  callTime: string;
  totalUsage: string;
  activeUsers: string;
  callProportion: string;
  allMembers: string;
  addMember: string;
  importMember: string;
  events: string;
  userProfile: string;
  eventDetails: string;
  media: string;
  common_v1: string;
  getAdminMemberProfile: string;
  profileImage: string;
  profileCover: string;
  changePassword: string;
  changePasswordAuth: string;
  requestToAdmin: string;
  deleteAccount: string;
  logout: string;
  activities: string;
  dispatcherCallList: string;
  search: string;
}

export const ApiRoutes: ApiRoutesInterface = {
  common: 'api/v1/dashboard/',
  register: 'register/',
  login: 'login/',
  sendOTP: 'otp/send/',
  verifyOTP: 'otp/verify/',
  resetPassword: 'reset_password/',
  userCount: 'summary/user-summary-data/',
  totalCalls: 'summary/total-call-time/',
  callTime: 'summary/total-calls/',
  totalUsage: 'summary/usage/',
  activeUsers: 'summary/new-active-users/',
  callProportion: 'summary/call-proportion/',
  allMembers: 'members/',
  addMember: 'members/',
  importMember: 'members/import/',
  events: 'events/',
  activities: 'activities/',
  media: 'media/',
  userProfile: 'user-details/',
  common_v1: 'api/v1/',
  eventDetails: 'trip/',
  getAdminMemberProfile: 'profile/',
  profileImage: 'image/',
  profileCover: 'cover-image/',
  changePassword: 'change-password/',
  requestToAdmin: 'request-admin/',
  deleteAccount: 'profile/',
  logout: 'logout/',
  changePasswordAuth: 'profile/change-password/',
  dispatcherCallList: 'dispatcher-calls/',
  search: 'search'
};
