import { Routes } from 'common/router-name';
import { BadgeBg } from 'components/base/Badge';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export type LatestReviewsTableDataType = {
  product?: string;
  productImage?: string;
  customer?: {
    name?: string;
    avatar?: string;
    variant?: 'image' | 'name';
    avatarPlaceholder?: boolean;
  };
  rating?: number;
  review?: string;
  status?: {
    title?: string;
    badgeBg?: BadgeBg;
    icon?: string;
  };
  time?: string;
  organization?: string;
  phone?: string;
};

const { Dashboard_Two } = Routes;

export const filterEventBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Home',
    url: Dashboard_Two,
    active: false
  },
  {
    label: 'Filtered User Activity',
    active: true
  }
];

export const latestReviewsTableData: LatestReviewsTableDataType[] = [];
