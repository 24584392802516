import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import { ToastContainer, Bounce } from 'react-toastify';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import StoreProvider from './redux/provider';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={800}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          transition={Bounce}
        />
        <QueryClientProvider client={queryClient}>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <RouterProvider router={router} />
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </QueryClientProvider>
      </AppProvider>
    </StoreProvider>
  </React.StrictMode>
);
