/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getDatesMonthChart } from 'helpers/utils';
import dayjs from 'dayjs';
import { useAppContext } from 'providers/AppProvider';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { ThemeVariant } from 'config';
import { tooltipFormatterList } from 'helpers/echart-utils';
import { ecomTotalSellsChartProps } from '../ecommerce/dashboard.interface';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const EcomTotalSellsChart = ({
  selectedData,
  totalCalls
}: ecomTotalSellsChartProps) => {
  const {
    config: { theme },
    getThemeColor
  } = useAppContext();

  const dates = getDatesMonthChart(
    new Date(),
    1000 * 60 * 60 * 24,
    selectedData
  );

  // const currentMonthData = [
  //   100, 200, 300, 300, 300, 250, 200, 200, 200, 200, 200, 500, 500, 500, 600,
  //   700, 800, 900, 1000, 1100, 850, 600, 600, 600, 400, 200, 200, 300, 300, 300
  // ];
  const currentMonthData = totalCalls?.currentMonth?.daily_call_counts;

  // const prevMonthData = [
  //   200, 200, 100, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 200, 400, 600,
  //   600, 600, 800, 1000, 700, 400, 450, 500, 600, 700, 650, 600, 550
  // ];
  const prevMonthData = totalCalls?.previousMonth?.daily_call_counts;

  const getDefaultOptions = (
    theme: ThemeVariant,
    getThemeColor: (name: string) => string
  ) => ({
    color: [getThemeColor('primary'), getThemeColor('info')],
    tooltip: {
      trigger: 'axis',
      padding: 10,
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: tooltipFormatterList
    },
    xAxis: [
      {
        type: 'category',
        data: dates,
        axisLabel: {
          formatter: (value: Date) => dayjs(value).format('DD MMM'),
          interval: 13,
          showMinLabel: true,
          showMaxLabel: false,
          color: getThemeColor('secondary-color'),
          align: 'left',
          fontFamily: 'Nunito Sans',
          fontWeight: 600,
          fontSize: 12.8
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getThemeColor('secondary-bg')
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          interval: 0,
          lineStyle: {
            color:
              theme === 'dark'
                ? getThemeColor('body-highlight-bg')
                : getThemeColor('secondary-bg')
          }
        },
        boundaryGap: false
      },
      {
        type: 'category',
        position: 'bottom',
        data: dates,
        axisLabel: {
          formatter: (value: Date) => dayjs(value).format('DD MMM'),
          interval: 130,
          showMaxLabel: true,
          showMinLabel: false,
          color: getThemeColor('secondary-color'),
          align: 'right',
          fontFamily: 'Nunito Sans',
          fontWeight: 600,
          fontSize: 12.8
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        },
        boundaryGap: false
      }
    ],
    yAxis: {
      position: 'right',
      axisPointer: { type: 'none' },
      axisTick: 'none',
      splitLine: {
        show: false
      },
      axisLine: { show: false },
      axisLabel: { show: false }
    },
    series: [
      {
        type: 'line',
        data: currentMonthData,
        showSymbol: false,
        symbol: 'circle'
      },
      {
        type: 'line',
        data: prevMonthData,
        lineStyle: {
          type: 'dashed',
          width: 1,
          color: getThemeColor('info')
        },
        showSymbol: false,
        symbol: 'circle'
      }
    ],
    grid: {
      right: 2,
      left: 5,
      bottom: '20px',
      top: '2%',
      containLabel: false
    },
    animation: false
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(theme, getThemeColor)}
      style={{ height: '316px', width: '100%' }}
    />
  );
};

export default EcomTotalSellsChart;
