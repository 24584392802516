import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import useDealsOtherInformationHook from './useDealsOtherInformationHook';
import useDetailsHook from './deals-details/useDetailsHook';

const DealsOtherInformation = () => {
  const { event_id } = useParams();
  const { handleUpdateTagComment } = useDealsOtherInformationHook();
  const { userDetails } = useDetailsHook();
  const [info, setInfo] = useState({
    tag: '',
    dispatcher_comment: ''
  });

  useEffect(() => {
    if (userDetails) {
      setInfo({
        tag: userDetails.tag || '',
        dispatcher_comment: userDetails.dispatcher_comment || ''
      });
    }
  }, [userDetails]);

  const validationSchema = Yup.object({
    tag: Yup.string().required('Tag is required'),
    dispatcher_comment: Yup.string().required('Comment is required')
  });

  const formik = useFormik({
    initialValues: {
      tag: info.tag, // Set default value
      dispatcher_comment: info.dispatcher_comment
    },
    validationSchema,
    onSubmit: values => {
      handleUpdateTagComment(values, event_id);
    },
    enableReinitialize: true
  });

  return (
    // <Card>
    //   <Card.Body>
    //     <h4 className="mb-5">Dispatch Input</h4>
    //     <Row className="g-3">
    //       <Col xs={12}>
    //         <div className="mb-4">
    //           <div className="d-flex flex-wrap justify-content-between mb-2">
    //             <h5 className="mb-0 text-body-highlight me-2">Tag</h5>
    //           </div>
    //           <Form.Select>
    //             <option value="SAFE">Safe</option>
    //             <option value="INCIDENT">Incident</option>
    //             <option value="CONNECTED_TO_911">Connected To 911</option>
    //             <option value="CONNECTIVITY_ISSUE">Connectivity Issues</option>
    //             <option value="NEEDS_ATTENTION">Needs Attention</option>
    //           </Form.Select>
    //         </div>
    //         <div className="mb-4">
    //           <h5 className="mb-0 text-body-highlight mb-2">Comment</h5>
    //           <Form.Control
    //             as="textarea"
    //             placeholder="Please provide additional context if necessary..."
    //             rows={5}
    //             style={{ resize: 'none' }}
    //           />
    //         </div>
    //       </Col>
    //     </Row>
    //   </Card.Body>
    // </Card>
    <Card>
      <Card.Body>
        <h4 className="mb-5">Dispatch Input</h4>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="g-3">
            <Col xs={12}>
              <div className="mb-4">
                <div className="d-flex flex-wrap justify-content-between mb-2">
                  <h5 className="mb-0 text-body-highlight me-2">Tag</h5>
                </div>
                <Form.Select
                  name="tag"
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.tag && !!formik.errors.tag}
                >
                  <option value="">Select</option>
                  <option value="SAFE">Safe</option>
                  <option value="INCIDENT">Incident</option>
                  <option value="CONNECTED_TO_911">Connected To 911</option>
                  <option value="CONNECTIVITY_ISSUE">
                    Connectivity Issues
                  </option>
                  <option value="NEEDS_ATTENTION">Needs Attention</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.tag}
                </Form.Control.Feedback>
              </div>
              <div className="mb-4">
                <h5 className="mb-0 text-body-highlight mb-2">Comment</h5>
                <Form.Control
                  as="textarea"
                  name="dispatcher_comment"
                  placeholder="Please provide additional context if necessary..."
                  rows={5}
                  style={{ resize: 'none' }}
                  value={formik.values.dispatcher_comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.touched.dispatcher_comment &&
                    !!formik.errors.dispatcher_comment
                  }
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.dispatcher_comment}
                </Form.Control.Feedback>
              </div>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DealsOtherInformation;
