// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Button from 'components/base/Button';
import { Card } from 'react-bootstrap';
import useUserEventProfileHook from './useUserEventProfileHook';
import { Link } from 'react-router-dom';

const EcoimDefaultAddressCard = () => {
  const { details, IsLoadingdetails } = useUserEventProfileHook();
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="border-bottom border-dashed">
          <h4 className="mb-3 lh-sm lh-xl-1">
            Information
            {/* <Button variant="link" className="p-0 ms-3" type="button">
              <FontAwesomeIcon icon={faEdit} className="text-body-quaternary" />
            </Button> */}
          </h4>
        </div>
        {IsLoadingdetails ? (
          <>Loading...</>
        ) : (
          <>
            <div className="border-bottom border-dashed">
              <div className="d-flex align-items-center flex-wrap justify-content-between py-3">
                <h5 className="text-body-highlight mb-0">Organization</h5>
                <p className="text-body-secondary mb-0">
                  {details?.organization ?? 'N/A'}
                </p>
                {/* University of Chicago */}
              </div>
            </div>
            <div className="border-bottom border-dashed">
              <div className="d-flex align-items-center justify-content-between gap-2 py-3">
                <h5 className="text-body-highlight mb-0">Email</h5>
                <Link
                  className="lh-1 text-right"
                  to={`${details?.email ? `mailto:${details?.email}` : '#'} `}
                >
                  {details?.email ?? 'N/A'}
                </Link>
              </div>
            </div>
            <div className="border-bottom border-dashed">
              <div className="d-flex align-items-center justify-content-between gap-2 py-3">
                <h5 className="text-body-highlight mb-0">Phone</h5>
                <p className="text-body-secondary mb-0">
                  {details?.phone_no ?? 'N/A'}
                </p>
                {/* +1234567890 */}
              </div>
            </div>
            <div className="">
              <div className="d-flex justify-content-between align-items-center gap-2 py-3">
                <h5 className="text-body-highlight mb-0">ID</h5>
                <p className="text-body-secondary mb-0">
                  {details?.student_id ?? 'N/A'}
                </p>
                {/* 123456790 */}
              </div>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default EcoimDefaultAddressCard;
