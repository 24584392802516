import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Row } from 'react-bootstrap';
import { memberBreadcrumbItems } from './membersData';
// import MembersTable from './MembersTable';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import useMemberHook from './useMemberHook';
import { Routes } from 'common/router-name';
import { Link, useNavigate } from 'react-router-dom';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { MembersProps } from './member.interface';
import { PageSize } from 'common/variables';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

const Members = () => {
  const navigate = useNavigate();
  const {
    handleSearchInputChange,
    memberList,
    isLoadingmember,
    downloadCSV,
    searchValue,
    joinedAt,
    formatTimestamp,
    pagination,
    setPagination
  } = useMemberHook();
  const { AddMembersRoute } = Routes;

  const membersTablecolumns: ColumnDef<MembersProps>[] = [
    {
      id: 'productImage',
      accessorKey: '',
      // cell: ({ row: { original } }) => {
      //   const { productImage } = original;
      //   return (
      //     <Link
      //       to="/apps/e-commerce/customer/product-details"
      //       className="d-block rounded-2 border border-translucent"
      //     >
      //       <img src={productImage} alt="" width={53} />
      //     </Link>
      //   );
      // },
      meta: {
        cellProps: { className: 'py-0' }
      },
      enableSorting: false
    },
    {
      accessorKey: 'name',
      header: 'Member',
      cell: ({ row: { original } }) => {
        const { first_name, last_name } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">{`${first_name} ${last_name}`}</p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '5%', minWidth: '0px' },
          className: 'px-1'
        },
        cellProps: { className: 'align-middle white-space-nowrap py-2' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => {
        const { email } = original;
        return (
          <>
            {email ? (
              <Link to={`mailto:${email}`} className="fw-semibold">
                {email}
              </Link>
            ) : (
              'N/A'
            )}
          </>
        );
      },
      meta: {
        headerProps: {
          style: { width: '15%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'mobile',
      header: 'Mobile Number',
      cell: ({ row: { original } }) => {
        const { phone_no } = original;
        return (
          <Link
            to={`tel:${phone_no ?? 'N/A'}`}
            className="fw-bold text-body-emphasis"
          >
            {phone_no ?? 'N/A'}
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' },
          className: 'pe-3'
        }
      }
    },
    {
      accessorKey: 'user_type',
      header: 'User Type',
      cell: ({ row: { original } }) => {
        const { user_type } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">
            {user_type === 'DA' ? 'Dispatcher Admin' : 'Dispatcher Standard'}
          </p>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row: { original } }) => {
        const { default_password } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">
            {default_password ? (
              <span style={{ color: '#A86A32' }}>Invite send - Pending</span>
            ) : (
              'Current User'
            )}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'last_active',
      header: 'Last Active',
      cell: ({ row: { original } }) => {
        const { last_active } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">
            {last_active ? formatTimestamp(last_active) : 'N/A'}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '19%', minWidth: '200px' },
          className: 'text-end pe-0'
        },
        cellProps: {
          className: 'text-body-tertiary text-end'
        }
      }
    },
    {
      accessorKey: 'joined',
      header: 'Joined',
      cell: ({ row: { original } }) => {
        const { created_at } = original;
        return (
          <p className="mb-0 text-body-emphasis fw-semibold">
            {joinedAt(created_at)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '19%', minWidth: '200px' },
          className: 'text-end pe-0'
        },
        cellProps: {
          className: 'text-body-tertiary text-end'
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: memberList?.results ?? [],
    columns: membersTablecolumns,
    pageSize: PageSize,
    pagination: true,
    sortable: true,
    selection: true,
    setPagination,
    pageCount: memberList?.pages,
    manualPagination: true,
    state: {
      pagination
    }
  });

  return (
    <>
      <PageBreadcrumb items={memberBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Members</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto" sm={6}>
                <SearchBox
                  placeholder="Search members using their email...."
                  onChange={handleSearchInputChange}
                  value={searchValue}
                  className="w-100"
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button
                  variant="link"
                  className="text-body me-4 px-0"
                  onClick={() => downloadCSV(memberList?.results ?? [])}
                >
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                <Button
                  variant="primary"
                  onClick={() => navigate(AddMembersRoute)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add member
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              isLoading={isLoadingmember}
            />
            <AdvanceTableFooter
              pagination
              showViewAllBtn={false}
              count={memberList?.count}
            />
          </div>
        </AdvanceTableProvider>
      </div>
    </>
  );
};

export default Members;
