import { Routes } from 'common/router-name';
import { redirect } from 'react-router-dom';
import Store from '../redux/store';

export const isAuthenticated = async () => {
  const state = Store.getState();
  // const isLock = state.auth.isLock;
  // if (isLock) throw redirect(`${Routes.Auth}${Routes.LockPage}`);
  const isLogin = state.auth.isLogin;
  if (isLogin) throw redirect(Routes.Dashboard_One);
  return null;
};
