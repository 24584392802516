import { Col, Row } from 'react-bootstrap';
import useDetailsHook from './useDetailsHook';

const DealDetailsAttachments = () => {
  const { imageDetails, IsLoadingImageDetails } = useDetailsHook();

  if (IsLoadingImageDetails) {
    return <>Loading...</>;
  }

  return (
    <Row>
      <Col xl={6} xxl={6} className="pe-0">
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {imageDetails?.results && imageDetails?.results?.length > 0 ? (
            <img
              src={imageDetails?.results[0]?.file ?? ''}
              alt="dummy"
              className="rounded-2 img-fluid"
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          ) : (
            <>No image found</>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DealDetailsAttachments;
