import EcomPayingVsNonPayingChart from './EcomPayingVsNonPayingChart';
import { Card } from 'react-bootstrap';

interface EcomPayingVsNonPayingCardProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isLoading: boolean;
}

const EcomPayingVsNonPayingCard = ({
  data,
  isLoading
}: EcomPayingVsNonPayingCardProps) => {
  if (isLoading) {
    return <>Loading...</>;
  }
  return (
    <Card className="h-100">
      <Card.Body className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">Call Proportion</h5>
            <h6 className="text-body-tertiary">Last 7 days</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-3 flex-1">
          <EcomPayingVsNonPayingChart data={data} />
        </div>
        <div className="mt-3">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">Made a call</h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.made_call ?? 0}%
            </h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-primary-subtle me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              Did not make a call
            </h6>
            <h6 className="text-body fw-semibold mb-0">
              {data?.not_made_call ?? 0}%
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomPayingVsNonPayingCard;
