import { Col, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircle,
  faPause,
  faSquare,
  faStar,
  faExclamation
} from '@fortawesome/free-solid-svg-icons';
import useDashboardHook from '../ecommerce/useDashboardHook';

type StatType = {
  id: number | string;
  icon: IconProp;
  title: string;
  subTitle: string;
  color: string;
};

const EcomStats = () => {
  const { ourUsers, isLoadingOurUsers } = useDashboardHook();
  if (isLoadingOurUsers) {
    return <>Loading...</>;
  }
  const stats: StatType[] = [
    {
      id: 1,
      icon: faStar,
      title: `${ourUsers?.new_users ?? 0} new users`,
      subTitle: 'Confirming their numbers',
      color: 'success'
    },
    {
      id: 2,
      icon: faPause,
      title: `${ourUsers?.slacking_users ?? 0} slacking users`,
      subTitle: 'Haven’t used the app in 1 week',
      color: 'warning'
    },
    {
      id: 3,
      icon: faExclamation,
      title: `${ourUsers?.dedicated_users ?? 0} dedicated users`,
      subTitle: 'Open the app daily',
      color: 'primary'
    }
  ];
  return (
    <Row className="align-items-center g-4">
      {stats.map(stat => (
        <Col xs={12} md="auto" key={stat.id}>
          <Stat stat={stat} />
        </Col>
      ))}
    </Row>
  );
};

const Stat = ({ stat }: { stat: StatType }) => {
  return (
    <Stack direction="horizontal" className="align-items-center">
      {/* <img src={stat.icon} alt="" height={46} width={46} /> */}
      <span
        className="fa-layers"
        style={{ minHeight: '46px', minWidth: '46px' }}
      >
        <FontAwesomeIcon
          icon={faSquare}
          size="2x"
          className={`text-${stat.color}-light dark__text-opacity-50`}
          transform="down-4 rotate--10 left-4"
        />
        <FontAwesomeIcon
          icon={faCircle}
          size="2x"
          className={`text-stats-circle-${stat.color} fa-layers-circle`}
          transform="up-4 right-3 grow-2"
        />
        <FontAwesomeIcon
          icon={stat.icon}
          size="1x"
          className={`text-${stat.color}`}
          transform="shrink-2 up-8 right-6"
        />
      </span>

      <div className="ms-3">
        <h4 className="mb-0">{stat.title}</h4>
        <p className="text-body-secondary fs-9 mb-0">{stat.subTitle}</p>
      </div>
    </Stack>
  );
};

export default EcomStats;
